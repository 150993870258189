import { useMemo } from 'react';
import { View } from 'react-native';
import { FaqTopics } from '@lawnstarter/customer-modules/enums';
import { getFaqByTopic, getOrderServiceInfo } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { RichText } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { Faq, Question } from '@lawnstarter/ls-react-common/molecules';

import { OrderPriceConfirmation } from '@src/components/molecules';

import { StyledAboutContainer, stylesLegacy } from './styles';

export function AboutLawnTreatment({
  onPress,
  price,
  isLoading = false,
}: {
  onPress: () => void;
  price: string | number;
  isLoading?: boolean;
}) {
  const origin = 'about_lawn_treatment';

  const theme = useAppTheme();
  const style = useMemo(() => stylesLegacy(theme), [theme]);

  const faqTopics = useMemo(() => getFaqByTopic({ topic: FaqTopics.lawnTreatment }), []);
  const serviceInfo = useMemo(
    () =>
      getOrderServiceInfo({
        service: ServiceType.LawnTreatment,
        priceInfo: { price: Number(price) },
      }),
    [price],
  );

  return (
    <StyledAboutContainer>
      <OrderPriceConfirmation
        origin={origin}
        isUpdating={isLoading}
        onButtonPress={onPress}
        labels={{
          title: serviceInfo?.service.prices[0],
          description: serviceInfo?.service.frequency,
          footer: serviceInfo?.service.billedUpon,
          button: t('continue'),
        }}
      />

      <View style={style.faqContainer}>
        <Faq header={t('customerFaq.faq')} style={{ wrapperScroll: { height: 340 } }}>
          <>
            {faqTopics.map(({ questions }) =>
              questions.map(({ question, answers }) => (
                <Question key={question} question={question} trackID={`${origin}-ltrs_faq_items`}>
                  <>
                    {answers.map(({ text, actions }) => (
                      <RichText
                        key={text}
                        linkActions={actions}
                        textStyle={style.faqInfo}
                        linkStyle={{ color: theme.colors.blue.primary }}
                      >
                        {text}
                      </RichText>
                    ))}
                  </>
                </Question>
              )),
            )}
          </>
        </Faq>
      </View>
    </StyledAboutContainer>
  );
}
