import { useMemo } from 'react';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { eventEmitter } from '@lawnstarter/customer-modules/helpers';
import { properties_currentPropertySelector } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Spinner, Text } from '@lawnstarter/ls-react-common/atoms';
import { EntityDisplay } from '@lawnstarter/ls-react-common/molecules';

import { useAppContext } from '@src/contexts';
import { MobileHeaderEvents } from '@src/enums';
import {
  useHelpDeskPressEvent,
  useMainRouteCheck,
  useRouteNavigation,
  useSelector,
} from '@src/hooks';

import { StyledHelpDeskContainer, StyledMobileHeader, styles } from './styles';

export function MobileHeader() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const { navigate } = useRouteNavigation();
  const { isMainRoute } = useMainRouteCheck();

  const { title, subTitle, backButtonAction } = useAppContext();
  const helpButtonEvent = useHelpDeskPressEvent();

  const currentProperty = useSelector(properties_currentPropertySelector);

  const renderBackButton = () => (
    <StyledHelpDeskContainer className="ml-4">
      <button
        data-testid="back-button"
        style={{ ...style.headerButton, padding: theme.sizing.s2, marginRight: 0 }}
        onClick={backButtonAction ? backButtonAction : () => navigate(WebRoutes.back)}
      >
        <Icon size={theme.sizing.s5} name="chevron-left" color={theme.colors.primary} />
      </button>
    </StyledHelpDeskContainer>
  );

  const renderPropertySwitcher = () => (
    <div className="flex flex-col w-11/12 mt-1 mx-auto">
      <EntityDisplay
        testID="property-switcher"
        icon="home"
        onSwitchRequest={() => eventEmitter.emit(MobileHeaderEvents.onPropertySelectPressEvent)}
        entity={currentProperty}
        labelExtractor={(entity) =>
          entity?.street || (
            <Spinner
              color={theme.colors.white.primary}
              size={theme.sizing.s4}
              style={{ marginHorizontal: theme.spacing.s2 }}
            />
          )
        }
      />
    </div>
  );

  const renderHelpButton = () => (
    <StyledHelpDeskContainer>
      <button
        data-testid="help-button"
        style={style.headerButton}
        onClick={() => eventEmitter.emit(helpButtonEvent!)}
      >
        <Icon name="help" size={13} color={theme.colors.primary} />
      </button>
    </StyledHelpDeskContainer>
  );

  return (
    <StyledMobileHeader style={style.wrapper} data-testid="mobile-header">
      {window.history.length > 1 && !isMainRoute && renderBackButton()}

      <div className="flex flex-col w-full">
        <div className="flex flex-col w-11/12 mx-auto">
          <Text variant="titleLarge" style={style.headerTitle}>
            {title}
          </Text>
          {subTitle && !isMainRoute && (
            <Text variant="bodyLarge" style={style.headerSubTitle}>
              {subTitle}
            </Text>
          )}
        </div>

        {isMainRoute && renderPropertySwitcher()}
      </div>

      {helpButtonEvent && renderHelpButton()}
    </StyledMobileHeader>
  );
}
