import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    safeArea: {
      flex: 1,
      marginBottom: 160, // Necessary to accommodate our new footer.
    },
    contentContainer: {
      flex: 1,
    },
    orderLawnTreatmentContainer: {
      backgroundColor: theme.colors.white.primary,
      padding: theme.spacing.s4,
      width: '100%',
    },
    orderLawnTreatmentSubtitle: {
      marginTop: theme.spacing.s5,
      ...setFontWeight('700'),
    },
    orderLawnTreatmentDescription: {
      marginTop: theme.spacing.s3,
    },
    orderLawnTreatmentItems: {
      marginTop: theme.spacing.s3,
      flexDirection: 'row',
    },
    planDetailsTitle: {
      marginTop: theme.spacing.s5,
      marginBottom: theme.spacing.s2,
      ...setFontWeight('500'),
    },
    imagesContainer: {
      marginTop: theme.spacing.s5,
      flexDirection: 'column',
    },
    smallImagesContainer: {
      flex: 1,
      alignItems: 'center',
      marginVertical: theme.spacing.s3,
    },
    smallImage: {
      height: 240,
      width: '100%',
    },
    smallImageSubtitle: {
      position: 'absolute',
      top: '110%',
      transform: [{ translateX: 0 }, { translateY: -50 }],
      color: 'white',
      textAlign: 'center',
    },
    footer: {
      padding: theme.spacing.s6,
      marginTop: theme.spacing.s2,
      marginBottom: 75,
      textAlign: 'center',
      color: theme.colors.gray.tertiary,
      ...setFontWeight('500'),
    },
  });
};

export const StyledOrderLawnTreatmentInfoContainer = tw.section`
  flex flex-col items-center w-full max-w-screen-lg mx-auto
`;
