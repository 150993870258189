import { useMemo } from 'react';
import { Image, View } from 'react-native';
import PoolCleaningPhoto1 from '@lawnstarter/customer-modules/assets/services/pool_image12.png';
import PoolCleaningPhoto3 from '@lawnstarter/customer-modules/assets/services/pool_image15.png';
import PoolCleaningPhoto2 from '@lawnstarter/customer-modules/assets/services/pool_image16.png';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';

import { AboutPoolCleaning, BackButton, ResponsiveRenderer } from '@src/components';

import { MobileYearLongPoolCleaningTemplate } from '../MobileYearLongPoolCleaningTemplate';
import {
  OrderPoolCleaningContainer,
  StyledOrderPoolCleaningContainer,
  StyledOrderPoolCleaningInfoContainer,
  styles,
} from './styles';

import type { ImageSourcePropType } from 'react-native';

export function YearLongPoolCleaningScreen() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const renderOrderPoolCleaningContainer = () => {
    return (
      <StyledOrderPoolCleaningInfoContainer>
        <BackButton />

        <OrderPoolCleaningContainer style={style.orderPoolCleaningContainer}>
          <Text variant="headlineLarge">{t('services.poolCleaning.yearLong.title')}</Text>

          <br />
          <View style={{ marginBottom: 16 }} />

          <Text variant="labelLarge" style={style.orderPoolCleaningSubtitle}>
            {t('services.poolCleaning.yearLong.header')}
          </Text>

          <View style={{ marginBottom: 12 }} />

          <Text variant="labelLarge" style={style.orderPoolCleaningDescription}>
            {t('services.poolCleaning.yearLong.description')}
          </Text>

          <View style={style.orderPoolCleaningItems}>
            <Icon name="cards-diamond-outline" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('services.poolCleaning.yearLong.crystalClearPoolFloor')}
            </Text>
          </View>

          <View style={style.orderPoolCleaningItems}>
            <Icon name="broom" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('services.poolCleaning.yearLong.algaeFreeTilesAndWalls')}
            </Text>
          </View>

          <View style={style.orderPoolCleaningItems}>
            <Icon name="swim" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('services.poolCleaning.yearLong.debrisFreeSurface')}
            </Text>
          </View>

          <View style={style.orderPoolCleaningItems}>
            <Icon name="waves" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('services.poolCleaning.yearLong.perfectWaterBalance')}
            </Text>
          </View>

          <View style={style.orderPoolCleaningItems}>
            <Icon name="filter-outline" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('services.poolCleaning.yearLong.efficientFiltration')}
            </Text>
          </View>

          <View>
            <Text variant="labelLarge" style={style.greatPoolTransformations}>
              {t('services.poolCleaning.yearLong.greatPoolTransformations')}
            </Text>
          </View>

          <View style={style.imagesContainer}>
            <View style={style.smallImagesContainer}>
              <Image source={PoolCleaningPhoto1 as ImageSourcePropType} style={style.smallImage} />
            </View>
            <View style={style.smallImagesContainerPadding}>
              <Image source={PoolCleaningPhoto2 as ImageSourcePropType} style={style.smallImage} />
            </View>
          </View>

          <View style={style.largeImagesContainer}>
            <Image source={PoolCleaningPhoto3 as ImageSourcePropType} style={style.largeImage} />
            <Text variant="bodySmall" style={style.footer}>
              {t('services.poolCleaning.yearLong.servicesWillBeCustomized')}
            </Text>
          </View>
        </OrderPoolCleaningContainer>
      </StyledOrderPoolCleaningInfoContainer>
    );
  };

  return (
    <ResponsiveRenderer>
      <ResponsiveRenderer.Portable>
        <MobileYearLongPoolCleaningTemplate />
      </ResponsiveRenderer.Portable>

      <ResponsiveRenderer.Desktop>
        <StyledOrderPoolCleaningContainer>
          <AboutPoolCleaning />
          {renderOrderPoolCleaningContainer()}
        </StyledOrderPoolCleaningContainer>
      </ResponsiveRenderer.Desktop>
    </ResponsiveRenderer>
  );
}
