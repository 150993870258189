import { Loader } from '@src/components';

import { StyledLeftPanel, StyledResponsiveContentWrapper, StyledRightPanel } from './styles';

import type { ResponsiveSectionWrapperProps } from './types';

/**
 * Provides a responsive section wrapper with left and right sections.
 * The left section can be hidden for mobile screens based on specified conditions.
 *
 * @example
 * // Responsive Section Wrapper usage
 * <ResponsiveSectionWrapper
 *  // If false: Right side children will not be displayed in any scenario and left side will take the whole screen size.
 *  hasRightContent={true}
 *
 *  // If true: Left side children will be hidden on mobile screens
 *  // If false: Right side children will be hidden on mobile screens
 *  hideLeftContentWhen={true}
 *
 *  leftChildren={<>Left side</>}
 *  rightChildren={<>Right side</>}
 *  leftClassName="p-4"
 *  rightClassName="m-4"
 *  />
 */
export function ResponsiveSectionWrapper({
  isLoading,
  hasRightContent,
  hideLeftContentWhen,
  leftChildren,
  rightChildren,
  leftClassName,
  rightClassName,
}: ResponsiveSectionWrapperProps) {
  if (isLoading) {
    return <Loader testID="loader-spinner" />;
  }

  return (
    <StyledResponsiveContentWrapper $withContent={hasRightContent}>
      <StyledLeftPanel
        $hideContent={hideLeftContentWhen}
        $withContent={hasRightContent}
        className={leftClassName}
      >
        {leftChildren}
      </StyledLeftPanel>

      <StyledRightPanel
        $hideContent={!hideLeftContentWhen}
        $withContent={hasRightContent}
        className={rightClassName}
      >
        {rightChildren}
      </StyledRightPanel>
    </StyledResponsiveContentWrapper>
  );
}
