import { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { Divider } from 'react-native-paper';
import { FaqTopics } from '@lawnstarter/customer-modules/enums';
import { getFaqByTopic } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Accordion, Button, RichText, Text } from '@lawnstarter/ls-react-common/atoms';

import { DetailsHeader, ExternalLink } from '@src/components';
import { useAppDownloadModal } from '@src/hooks';

import { FaqContainer, FaqSectionContainer, styles } from './styles';

import type { FAQTopicQuestion } from '@lawnstarter/customer-modules/helpers';

export function HelpScreen() {
  const faqs = getFaqByTopic({ topic: FaqTopics.customerProfile });
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);
  const { showAppDownloadModal } = useAppDownloadModal();

  const handleClick = (route: string) => {
    window.open(route, '_blank');
  };

  const renderQuestion = useCallback(
    ({ question, answers }: FAQTopicQuestion) => (
      <FaqSectionContainer key={question}>
        <Accordion style={{ title: { ...style.question } }} title={question} trackID="">
          <View>
            {answers.map(({ text, links }) => (
              <>
                <RichText textStyle={style.questionAnswer}>{text}</RichText>
                {links && (
                  <ExternalLink
                    iconSize={18}
                    text={links[0]?.text}
                    action={() => handleClick(links[0]?.target)}
                  />
                )}
              </>
            ))}
          </View>
        </Accordion>

        <Divider />
      </FaqSectionContainer>
    ),
    [style],
  );

  return (
    <div>
      <DetailsHeader />
      <FaqContainer>
        {faqs.map(({ title, questions }, index) => (
          <FaqSectionContainer key={`main-${index}`}>
            {title && (
              <Text style={style.sectionTitle} variant="titleLarge">
                {title}
              </Text>
            )}

            {questions.map(renderQuestion)}
          </FaqSectionContainer>
        ))}

        {/* TODO: replace with app modal once complete */}
        <Button
          mode="contained"
          buttonColor={theme.colors.primary}
          trackID="faq_screen-still_need_help"
          onPress={showAppDownloadModal}
        >
          {t('customerFaq.stillNeedHelp')}
        </Button>
      </FaqContainer>
    </div>
  );
}
