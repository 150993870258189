import { MobileHeaderEvents } from '@src/enums';

import { BaseBottomSheet } from '../BaseBottomSheet';

interface HelperBottomSheetProps {
  content: React.ReactNode;
  title: React.ReactNode;
}
export const HelperBottomSheet = ({ content, title }: HelperBottomSheetProps) => {
  return (
    <BaseBottomSheet openEvent={MobileHeaderEvents.onHelpDeskSelectPressEvent} title={title}>
      {content}
    </BaseBottomSheet>
  );
};
