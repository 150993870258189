import { Image } from 'react-native';
import PoolSkimming from '@lawnstarter/customer-modules/assets/modal-images/pool-skimming.png';
import { POOL_COLOR_OPTIONS } from '@lawnstarter/customer-modules/constants';
import { useOrderPoolFlowController } from '@lawnstarter/customer-modules/controllers';
import { t } from '@lawnstarter/customer-modules/services';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { OrderPoolCleaningLayout } from '@src/components';

import type { ImageSourcePropType } from 'react-native';

export function PoolCurrentColorScreen() {
  const modal = useModal(ModalTemplate.SPINNER);

  const { selectedColorOption, onColorSelection, goToPreviousStep } = useOrderPoolFlowController({
    spinnerModal: {
      instance: modal,
      image: (
        <Image source={PoolSkimming as ImageSourcePropType} style={{ height: 120, width: 99 }} />
      ),
    },
  });

  return (
    <OrderPoolCleaningLayout
      title={t('services.poolCleaning.orderService.poolColor.title')}
      description={t('services.poolCleaning.orderService.poolColor.description')}
      progress={2 / 4}
      onBackButtonPress={goToPreviousStep}
    >
      <SelectOption
        options={POOL_COLOR_OPTIONS}
        onSelect={onColorSelection}
        selected={selectedColorOption}
      />
    </OrderPoolCleaningLayout>
  );
}
