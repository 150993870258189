import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { getOrderServiceInfo } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_currentPropertySelector } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';

import { ResponsiveRenderer } from '@src/components';
import { BackButton } from '@src/components/atoms';
import { useDeviceType, useRouteNavigation, useSelector } from '@src/hooks';
import { errorService } from '@src/services';

import { OrderPriceConfirmation } from '../OrderPriceConfirmation';
import {
  BackButtonWrapper,
  StyledContentContainer,
  StyledOrderDetailsConfirmationContainer,
  StyledOrderInfoContainer,
  StyledOrderNowContainer,
  styles,
} from './styles';

import type { CustomerWithPayment } from '@lawnstarter/customer-modules/types';
import type { DetailsConfirmationProps } from '../types';

export function OrderDetailsConfirmation({
  origin,
  service,
  priceInfo,
  onSubmit,
  onBackButtonPress,
  isUpdating = false,
  withPaymentSection = true,
  withOrderButton = true,
  labels,
}: DetailsConfirmationProps) {
  const theme = useAppTheme();
  const { navigate } = useRouteNavigation();
  const deviceType = useDeviceType();

  const style = useMemo(() => styles(theme, deviceType), [theme, deviceType]);
  const serviceInfo = useMemo(
    () => getOrderServiceInfo({ service, priceInfo }),
    [service, priceInfo],
  );

  const property = useSelector(properties_currentPropertySelector);
  const customer: CustomerWithPayment = useSelector(({ auth }) => auth.user.customer);

  useEffect(() => {
    if (!serviceInfo) {
      errorService.log({
        error: t('orderConfirmation.errorMessage', { service }),
        showAlert: true,
      });

      navigate(WebRoutes.browse, {
        params: { propertyId: property?.id },
        options: { replace: true },
      });
    }
  }, [serviceInfo, service, property, navigate]);

  const backAction = useCallback(
    () => (onBackButtonPress ? onBackButtonPress() : navigate(WebRoutes.back)),
    [navigate, onBackButtonPress],
  );

  const renderPaymentSection = useCallback(() => {
    if (!customer || !withPaymentSection) {
      return null;
    }

    const paymentMethod = _.isEmpty(customer.payment_brand)
      ? t('orderConfirmation.paymentMethod.cardWithoutBrand', { last4: customer.last4 })
      : t('orderConfirmation.paymentMethod.cardWithBrand', {
          brand: customer.payment_brand,
          last4: customer.last4,
        });

    return (
      <>
        <Text variant="bodyLarge" style={style.sectionTitle}>
          {t('orderConfirmation.paymentMethod.title')}
        </Text>

        <Text>{paymentMethod}</Text>

        <View style={style.divider}></View>
      </>
    );
  }, [withPaymentSection, customer, style]);

  const renderServiceSection = useCallback(
    () => (
      <>
        <Text variant="bodyLarge" style={style.sectionTitle}>
          {t('orderConfirmation.serviceSectionTitle')}
        </Text>

        <View style={style.sectionWrapper}>
          <Text>{serviceInfo?.service.name}</Text>
          <View>
            {serviceInfo?.service.prices.map((servicePrice) => (
              <Text key={servicePrice} style={style.servicePrice}>
                {servicePrice}
              </Text>
            ))}

            <Text style={{ textAlign: 'right' }}>{serviceInfo?.service.frequency}</Text>
          </View>
        </View>

        <View style={style.divider}></View>
      </>
    ),
    [serviceInfo, style],
  );

  return (
    <StyledOrderDetailsConfirmationContainer>
      <StyledOrderInfoContainer>
        <BackButtonWrapper>
          <BackButton onPress={backAction} />
        </BackButtonWrapper>

        <StyledContentContainer>
          <Text variant="bodyLarge" style={style.sectionTitle}>
            {t('orderConfirmation.addressSectionTitle')}
          </Text>

          <Text>
            {property.street}, {property.city}
          </Text>
          <Text>
            {property.state} {property.zip}
          </Text>

          <View style={style.divider} />

          {renderServiceSection()}

          {renderPaymentSection()}

          <View style={style.dueSectionWrapper}>
            <View style={style.dueDay}>
              <Text variant="bodyLarge" style={[style.sectionTitle, style.dueDayTitle]}>
                {t('orderConfirmation.dueSection.title')}
              </Text>

              <Text variant="bodyLarge" style={[style.sectionTitle, style.dueDayTitle]}>
                {t('orderConfirmation.dueSection.dueAmount')}
              </Text>
            </View>

            <Text style={style.dueDetails}>{t('orderConfirmation.dueSection.details')}</Text>
            <Text>{serviceInfo?.startDate}</Text>
          </View>
        </StyledContentContainer>

        <ResponsiveRenderer hidden={!withOrderButton}>
          <ResponsiveRenderer.Portable>
            <Button
              trackID={`${origin}-submit_order`}
              onPress={onSubmit}
              loading={isUpdating}
              mode="contained"
              style={{ flex: 1, marginTop: theme.spacing.s8 }}
            >
              {t('orderNow')}
            </Button>
          </ResponsiveRenderer.Portable>
        </ResponsiveRenderer>
      </StyledOrderInfoContainer>

      <StyledOrderNowContainer>
        <OrderPriceConfirmation
          origin={origin}
          isUpdating={isUpdating}
          onButtonPress={onSubmit}
          labels={
            labels ?? {
              title: serviceInfo?.service.prices[0],
              description: serviceInfo?.service.frequency,
              footer: serviceInfo?.service.billedUpon,
              button: t('orderNow'),
            }
          }
        />
      </StyledOrderNowContainer>
    </StyledOrderDetailsConfirmationContainer>
  );
}
