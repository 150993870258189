import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common/helpers';

import { DeviceType } from '@src/enums';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme, deviceType: DeviceType) => {
  return StyleSheet.create({
    container: {},
    divider: {
      borderWidth: 1,
      borderColor: theme.colors.grayLight,
      marginVertical: theme.spacing.s3,
    },
    sectionTitle: {
      fontSize: deviceType === DeviceType.Portable ? undefined : theme.fonts.bodyMedium.fontSize,
      marginBottom: theme.spacing.s3,
      ...setFontWeight('500'),
    },
    sectionWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    servicePrice: {
      textAlign: 'right',
      marginBottom: theme.spacing.s3,
    },
    dueSectionWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    dueDay: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dueDayTitle: { ...setFontWeight('700') },
    dueDetails: { marginBottom: theme.spacing.s1 },
  });
};

export const StyledOrderDetailsConfirmationContainer = tw.div`
  flex flex-col gap-0 items-start w-full py-4
  md:flex-row md:gap-6
`;

export const BackButtonWrapper = tw.div`
  hidden -mt-4 mb-3
  md:flex
`;

export const StyledOrderNowContainer = tw.div`
  hidden w-full mt-8
  md:flex
`;

export const StyledOrderInfoContainer = tw.div`
  w-full mx-auto 
  md:w-8/12
`;

export const StyledContentContainer = tw.div`
  bg-whitePrimary rounded-lg flex flex-col border border-graySecondary p-6 w-full
  max-md:border-none max-md:p-0
  md:w-[454px]
  lg:w-[549px]
  xl:w-[613px]
  [@media(min-width:2000px)]:w-[900px]
`;
