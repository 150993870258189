import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';

import { ResponsiveSectionWrapper } from '@src/components';
import { HeaderTabs } from '@src/enums';
import { AuthorizedTemplate } from '@src/pages';

import { ProfileScreen } from './ProfileScreen';

import type { User } from '@lawnstarter/ls-react-common/types';

export function AuthorizedProfileTemplate({ user }: { user?: User }) {
  const { pathname } = useLocation();

  return (
    <AuthorizedTemplate currentTab={HeaderTabs.profile} user={user}>
      <ResponsiveSectionWrapper
        hideLeftContentWhen={pathname !== WebRoutes.profile}
        leftChildren={<ProfileScreen />}
        hasRightContent={true}
        rightChildren={<Outlet />}
      />
    </AuthorizedTemplate>
  );
}
