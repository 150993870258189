import tw from 'tailwind-styled-components';

export const Container = tw.div`
  p-4
  md:p-0
`;

export const BackButtonWrapper = tw.div`
  hidden mb-3
  md:flex
`;

export const LayoutWrapper = tw.div`
  flex flex-col items-start gap-x-4
  md:flex-row md:items-start
  md:gap-x-20
`;

export const TitleWrapper = tw.div`
  flex flex-row items-start mt-2
`;

export const Title = tw.div`
  text-2xl font-semibold
  sm:text-3xl sm:font-medium
`;

export const HelpButton = tw.div`
  hidden ml-2 mt-0.5
  md:inline
`;

export const Description = tw.div`
  text-sm mt-2 mb-0
  sm:mt-4 sm:mb-4
  md:text-lg md:mb-2
`;

export const SelectOptionWrapper = tw.div`
  flex-1 w-full mt-6
  sm:mt-4
  md:max-w-[400px] md:mt-0
  lg:max-w-[500px]
`;
