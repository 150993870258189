import { useMatch } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';

import { useAppContext } from '@src/contexts';
import { MobileHeaderEvents } from '@src/enums';

import type { Nullable } from '@lawnstarter/ls-react-common/types';

export const useHelpDeskPressEvent = (): Nullable<MobileHeaderEvents> => {
  const { helpButtonEvent } = useAppContext();

  // Avoid adding more routes to this list
  // Instead use setHelpButtonEvent from the app context
  const serviceDetails = useMatch(WebRoutes.serviceDetail);
  const recommendedDetails = useMatch(WebRoutes.recommendedQuoteDetails);
  const requiredDetails = useMatch(WebRoutes.requiredQuoteDetails);
  const orderLawnTreatment = useMatch(WebRoutes.orderLawnTreatment);

  if (helpButtonEvent) {
    return helpButtonEvent;
  }

  const matches = [serviceDetails, recommendedDetails, requiredDetails, orderLawnTreatment];
  if (matches.some((match) => !!match)) {
    return MobileHeaderEvents.onHelpDeskSelectPressEvent;
  }

  return null;
};
