import { useMemo } from 'react';
import { Image, SafeAreaView, View } from 'react-native';
import Slider from 'react-slick';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';

import { OrderPriceConfirmation, ResponsiveRenderer } from '@src/components';
import { StyledCarouselContainer } from '@src/components/organisms/RecommendationCarousel/styles.ts';
import { useYearLongPoolController } from '@src/controllers';

import { StyledOrderPoolCleaningInfoContainer, styles } from './styles';

import type { ImageSourcePropType } from 'react-native';

export function MobileYearLongPoolCleaningTemplate() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const { onCustomizePress, getCarouselItems } = useYearLongPoolController();

  const renderItems = () => {
    return getCarouselItems().map((item, index) => (
      <View key={`pool@${index}`} style={style.imagesContainer}>
        <View style={style.smallImagesContainer}>
          <Image source={item.imageUrl as ImageSourcePropType} style={style.smallImage} />
          <Text variant="labelMedium" style={style.smallImageSubtitle}>
            {item.text}
          </Text>
        </View>
      </View>
    ));
  };

  const renderOrderPoolCleaningContainer = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
    };

    return (
      <StyledCarouselContainer>
        <Slider {...settings}>{renderItems()}</Slider>
      </StyledCarouselContainer>
    );
  };

  return (
    <SafeAreaView style={style.safeArea}>
      <ResponsiveRenderer>
        <ResponsiveRenderer.Portable>
          <ScrollView contentContainerStyleOverride={style.contentContainer}>
            <StyledOrderPoolCleaningInfoContainer>
              <View style={style.orderPoolCleaningContainer}>
                <Text variant="labelLarge" style={style.orderPoolCleaningSubtitle}>
                  {t('services.poolCleaning.yearLong.header')}
                </Text>

                <Text variant="labelLarge" style={style.orderPoolCleaningDescription}>
                  {t('services.poolCleaning.yearLong.description')}
                </Text>

                <View style={style.orderPoolCleaningItems}>
                  <Icon name="cards-diamond-outline" size={theme.sizing.s6} />
                  <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                    {t('services.poolCleaning.yearLong.crystalClearPoolFloor')}
                  </Text>
                </View>

                <View style={style.orderPoolCleaningItems}>
                  <Icon name="broom" size={theme.sizing.s6} />
                  <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                    {t('services.poolCleaning.yearLong.algaeFreeTilesAndWalls')}
                  </Text>
                </View>

                <View style={style.orderPoolCleaningItems}>
                  <Icon name="swim" size={theme.sizing.s6} />
                  <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                    {t('services.poolCleaning.yearLong.debrisFreeSurface')}
                  </Text>
                </View>

                <View style={style.orderPoolCleaningItems}>
                  <Icon name="waves" size={theme.sizing.s6} />
                  <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                    {t('services.poolCleaning.yearLong.perfectWaterBalance')}
                  </Text>
                </View>

                <View style={style.orderPoolCleaningItems}>
                  <Icon name="filter-outline" size={theme.sizing.s6} />
                  <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                    {t('services.poolCleaning.yearLong.efficientFiltration')}
                  </Text>
                </View>

                <View>
                  <Text variant="labelLarge" style={style.greatPoolTransformations}>
                    {t('services.poolCleaning.yearLong.greatPoolTransformations')}
                  </Text>
                </View>

                {renderOrderPoolCleaningContainer()}

                <Text variant="bodySmall" style={style.footer}>
                  {t('services.poolCleaning.yearLong.servicesWillBeCustomized')}
                </Text>
              </View>
            </StyledOrderPoolCleaningInfoContainer>
          </ScrollView>
        </ResponsiveRenderer.Portable>

        <ResponsiveRenderer.Desktop>
          <View>
            <Text variant="headlineLarge">{t('services.poolCleaning.yearLong.title')}</Text>
          </View>
        </ResponsiveRenderer.Desktop>
      </ResponsiveRenderer>

      <OrderPriceConfirmation
        isFloating={true}
        origin="about_pool_cleaning"
        onButtonPress={onCustomizePress}
        labels={{
          title: t('services.poolCleaning.yearLong.poolPerfection'),
          button: t('services.poolCleaning.yearLong.letsCustomizeYourPlan'),
        }}
      />
    </SafeAreaView>
  );
}
