import { useCallback } from 'react';
import { ScrollView } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';

import { OrderDetailsConfirmation, ResponsiveRenderer } from '@src/components';
import { useOrderLawnTreatmentController } from '@src/controllers';

export const ConfirmLawnTreatmentScreen = () => {
  const { onSubmit, isUpdating, lawnTreatmentInfo } = useOrderLawnTreatmentController();

  const renderContent = useCallback(
    () => (
      <OrderDetailsConfirmation
        origin="order_lawn_treatment"
        service={ServiceType.LawnTreatment}
        priceInfo={{ price: Number(lawnTreatmentInfo?.options[0].price ?? 0) }}
        isUpdating={isUpdating}
        onSubmit={onSubmit}
        withPaymentSection={false}
      />
    ),
    [isUpdating, lawnTreatmentInfo, onSubmit],
  );

  return (
    <ResponsiveRenderer>
      <ResponsiveRenderer.Portable>
        <ScrollView>{renderContent()}</ScrollView>
      </ResponsiveRenderer.Portable>

      <ResponsiveRenderer.Desktop>{renderContent()}</ResponsiveRenderer.Desktop>
    </ResponsiveRenderer>
  );
};
