import { StyleSheet } from 'react-native';
import { AppTheme, setFontWeight } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    modalTitle: {
      ...theme.fonts.titleLarge,
      ...setFontWeight('600'),
      marginTop: theme.spacing.s2,
    },
    modalImage: {
      width: 139,
      height: 152,
    },
  });
};
