import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const stylesLegacy = (theme: AppTheme) => {
  return StyleSheet.create({
    faqContainer: {
      borderWidth: 1,
      borderRadius: theme.sizing.s2,
      borderColor: '#D8D8D8',
      paddingRight: theme.sizing.s5,
      paddingLeft: theme.sizing.s5,
      width: 'auto',
      marginTop: -theme.sizing.s5,
      backgroundColor: theme.colors.white.primary,
    },
    faqInfo: {
      marginTop: theme.sizing.s3,
      marginLeft: theme.sizing.s1,
    },
  });
};

export const StyledAboutContainer = tw.div`
  flex flex-col items-center justify-center gap-10 w-full mx-auto mt-12 sticky top-[140px]
`;
