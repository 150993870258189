import { EXPO_PUBLIC_HELP_CENTER_CUSTOMER_POLICY_FAQ_WITHIN_48H } from '@env';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useConfirmCancellationController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, RichText, ScrollView, Toast } from '@lawnstarter/ls-react-common/atoms';
import { CancellationReason } from '@lawnstarter/ls-react-common/enums';
import { currency } from '@lawnstarter/ls-react-common/filters';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ConfirmCancellationForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useAppContext } from '@src/contexts';
import { useRouteNavigation } from '@src/hooks';
import { NewPropertyFormModal } from '@src/modals';

import { StyledButtonContainer, StyledContainer } from './styles';

import type { GetCancellationDetailsResponse } from '@lawnstarter/customer-modules/stores/modules';

export function ConfirmCancelScreen() {
  const confirmationModal = useModal(ModalTemplate.CONFIRMATION);
  const { navigate } = useRouteNavigation();
  const { propertyId, scheduleId, cancellationReason } = useParams();
  const { setSubTitle } = useAppContext();
  const { state } = useLocation();

  const cancellationDetails = state?.cancellationDetails as GetCancellationDetailsResponse;

  const newPropertyModal = useModal(NewPropertyFormModal);

  const {
    cancellationFormFields,
    control,
    handleSubmit,
    hideReasonSelector,
    isLoading,
    serviceName,
    withIssueNotes,
  } = useConfirmCancellationController({
    confirmationModal,
    onAddNewProperty: () => {
      confirmationModal.hide();
      setTimeout(() => newPropertyModal.show(), 100);
    },
    onSuccess: () => {
      navigate(WebRoutes.services, {
        options: { replace: true },
      });
    },
    onUpdatePaymentMethod: () => {
      navigate(WebRoutes.updatePayment, {
        options: { replace: true },
      });
    },
    propertyId: propertyId as string,
    reason: cancellationReason as CancellationReason,
    scheduleId: scheduleId as string,
  });

  useEffect(() => {
    if (serviceName) {
      setSubTitle(serviceName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceName]);

  const onHelpCenterLinkPress = () => {
    window.open(EXPO_PUBLIC_HELP_CENTER_CUSTOMER_POLICY_FAQ_WITHIN_48H, '_blank');
  };

  const renderFeeToast = () => {
    if (cancellationDetails?.feeAmount && cancellationReason !== CancellationReason.Moving) {
      const formattedFeeAmount = currency({
        amount: cancellationDetails.feeAmount * 0.01,
        trimTrailingZeros: true,
      });

      return (
        <Toast
          msToHide={0}
          title={t('cancelService.confirmation.applicableFees.title')}
          description={
            <RichText linkActions={{ onHelpCenterLinkPress }}>
              {t('cancelService.confirmation.applicableFees.description', {
                feeAmount: formattedFeeAmount,
              })}
            </RichText>
          }
        />
      );
    }
  };

  return (
    <>
      <DetailsHeader showBackButton />
      <ScrollView>
        <StyledContainer>
          <ConfirmCancellationForm
            control={control}
            defaultReason={cancellationReason}
            fields={cancellationFormFields}
            hideReasonSelector={hideReasonSelector}
            isUpdating={isLoading}
            origin="cancel_service_screen"
            withIssueNotes={withIssueNotes}
          />
          {renderFeeToast()}
        </StyledContainer>
      </ScrollView>

      <StyledButtonContainer>
        <Button
          trackID="confirm_cancellation_screen-confirm_cancellation"
          onPress={handleSubmit}
          mode="contained"
          loading={isLoading}
          disabled={isLoading}
        >
          {t('cancelService.confirmCancelation').toUpperCase()}
        </Button>
      </StyledButtonContainer>
    </>
  );
}
