import { useCallback, useEffect } from 'react';
import { OrderContextProvider, useOrderContext } from '@lawnstarter/customer-modules/contexts';
import { OrderPoolFlowKeys } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';

import { useAppContext } from '@src/contexts';
import { MobileHeaderEvents } from '@src/enums';

import { PoolAboveGroundScreen } from '../PoolAboveGroundScreen';
import { PoolCurrentColorScreen } from '../PoolCurrentColorScreen';
import { PoolOrderConfirmationScreen } from '../PoolOrderConfirmationScreen';
import { PoolSelectPlanScreen } from '../PoolSelectPlanScreen';
import { PoolVisibleIssuesScreen } from '../PoolVisibleIssuesScreen';

const PoolCleaningQuestions = () => {
  const { currentStep } = useOrderContext();
  const { setTitle, setHelpButtonEvent } = useAppContext();

  const getScreenTitle = useCallback((key: OrderPoolFlowKeys) => {
    switch (key) {
      case OrderPoolFlowKeys.SelectPlan:
        return t('services.poolCleaning.appTitles.selectPlan');

      case OrderPoolFlowKeys.ConfirmOrder:
        return t('services.poolCleaning.appTitles.confirmOrderDetails');

      default:
        return t('services.poolCleaning.appTitles.answerQuestions');
    }
  }, []);

  useEffect(() => {
    const currentKey = currentStep.key as OrderPoolFlowKeys;
    setTitle(getScreenTitle(currentKey));

    if (currentKey === OrderPoolFlowKeys.SelectPlan) {
      setHelpButtonEvent(MobileHeaderEvents.onFaqsPressEvent);
    }

    return () => {
      setHelpButtonEvent(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep.key]);

  return currentStep.screen;
};

export const PoolCleaningQuestionsFlow = () => {
  return (
    <OrderContextProvider
      steps={{
        [OrderPoolFlowKeys.IsPoolAboveGround]: <PoolAboveGroundScreen />,
        [OrderPoolFlowKeys.CurrentPoolColor]: <PoolCurrentColorScreen />,
        [OrderPoolFlowKeys.VisibleIssues]: <PoolVisibleIssuesScreen />,
        [OrderPoolFlowKeys.SelectPlan]: <PoolSelectPlanScreen />,
        [OrderPoolFlowKeys.ConfirmOrder]: <PoolOrderConfirmationScreen />,
      }}
    >
      <PoolCleaningQuestions />
    </OrderContextProvider>
  );
};
