import { EXPO_PUBLIC_ENV } from '@env';
import { useCallback, useEffect } from 'react';

export const useHotjar = (hotjarId: number, hotjarVersion: number) => {
  const appendHotjarImpl = useCallback(() => {
    const hotjarScript = document.createElement('script');
    hotjarScript.dataset.name = 'hotjar';
    hotjarScript.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hotjarId},hjsv:${hotjarVersion}};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.setAttribute("data-name", "hotjar_impl");
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;

    document.head.appendChild(hotjarScript);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isHotjarEnabled = document.querySelector("script[data-name~='hotjar']");

    // We'll be adding the hotjar script only in production and orange environments in order to
    // make it possible to be tested in a lower environment
    if (!isHotjarEnabled && ['production', 'orange'].includes(EXPO_PUBLIC_ENV)) {
      return appendHotjarImpl();
    }
  }, [appendHotjarImpl, hotjarId, hotjarVersion]);
};
