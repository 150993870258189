import {
  EXPO_PUBLIC_CANCELLATION_SUPPORT_PHONE_NUMBER,
  EXPO_PUBLIC_CANCELLATION_ZENDESK_WEB_CHANNEL_KEY,
  EXPO_PUBLIC_ZENDESK_WEB_CHANNEL_KEY,
} from '@env';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ActionListSectionItem } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader, Loader } from '@src/components';
import { useScript } from '@src/hooks';

import { style, StyledContainer } from './styles';

export function HelpAndSupportScreen() {
  const theme = useAppTheme();

  const { cancellationReason } = useParams();
  const [loading, setLoading] = useState(true);
  const styles = useMemo(() => style(theme), [theme]);

  const phoneModal = useModal(ModalTemplate.PHONE);

  useScript(
    `https://static.zdassets.com/ekr/snippet.js?key=${cancellationReason ? EXPO_PUBLIC_CANCELLATION_ZENDESK_WEB_CHANNEL_KEY : EXPO_PUBLIC_ZENDESK_WEB_CHANNEL_KEY}`,
    'ze-snippet',
  );

  // Keep checking for Zendesk script availability
  useEffect(() => {
    const interval = setInterval(() => {
      if (typeof window.zE === 'function') {
        clearInterval(interval);
        setLoading(false);
      }
    }, 100);

    return () => {
      clearInterval(interval);
      setLoading(false);
    };
  }, []);

  const openZendeskMessaging = () => {
    window.zE?.('messenger', 'open');
  };

  const onPhonePress = () => {
    phoneModal.show({
      headerStyle: styles.modalHeader,
      phoneStyle: styles.modalPhoneStyle,
      containerStyle: styles.modalContainerStyle,
      icon: 'phone-outline',
      stacked: 'always',
      callNowButtonMode: 'contained',
      cancelButtonMode: 'text',
      iconSize: 60,
      phone: EXPO_PUBLIC_CANCELLATION_SUPPORT_PHONE_NUMBER,
      cancelLabel: t('close'),
      headerLabel: t('helpAndSupport.phone.modalLabel'),
      onPhoneNumberPress: (phone: string) => {
        open(`tel:${phone}`);
      },
    });
  };

  return (
    <>
      <DetailsHeader showBackButton />
      <StyledContainer>
        {loading ? (
          <Loader testID="loader" />
        ) : (
          <>
            <Text variant="titleMedium" style={styles.availability}>
              {t('helpAndSupport.availability')}
            </Text>

            <ActionListSectionItem
              keepChevron
              action={openZendeskMessaging}
              label={t('helpAndSupport.messaging.title')}
              trackID="zendesk_online_chat-help_and_support_screen"
              icon={<Icon name="message-text-outline" size={theme.sizing.s6} />}
            />

            {cancellationReason && (
              <ActionListSectionItem
                keepChevron
                action={onPhonePress}
                label={t('helpAndSupport.phone.title')}
                trackID="zendesk_online_chat-help_and_support_screen"
                icon={<Icon name="phone-outline" size={theme.sizing.s6} />}
              />
            )}
          </>
        )}
      </StyledContainer>
    </>
  );
}
