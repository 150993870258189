import { useFlagsmith } from 'flagsmith/react';
import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  auth_checkForTokenAndLogin,
  auth_getAuthtokenFromAccessCode,
  auth_signedUserSelector,
} from '@lawnstarter/customer-modules/stores/modules';

import { Loader } from '@src/components';
import { useDispatch, useSegment, useSelector } from '@src/hooks';
import { GuestTemplate, NoMatch, PasswordRecoveryScreen, SignInScreen } from '@src/pages';
import { authService } from '@src/services';

import { BrowseRouter } from './BrowseRouter';
import { InboxRouter } from './InboxRouter';
import { ProfileRouter } from './ProfileRouter';
import { PropertyRouter } from './PropertyRouter';
import { ServicesRouter } from './ServicesRouter';

export function GlobalRouter() {
  const dispatch = useDispatch();
  const [isAppStarting, setAppStarting] = useState(true);
  const user = useSelector(auth_signedUserSelector);
  const authLoadingStatuses = useSelector(({ auth }) => auth.loadingStatus);
  const flagsmith = useFlagsmith();

  useSegment();

  useEffect(() => {
    if (user?.hash_id) {
      const context = flagsmith.getContext();
      if (context?.identity?.identifier !== user.hash_id) {
        flagsmith.setContext({ identity: { identifier: user.hash_id } });
      }
    } else {
      flagsmith.logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    (async () => {
      const url = new URL(window.location.href);
      const access = url.searchParams.get('access');
      const token = url.searchParams.get('auth');
      const intent = url.searchParams.get('intent');

      if (!token && access) {
        await dispatch(auth_getAuthtokenFromAccessCode({ access }));
        url.searchParams.delete('access');
      }

      // if the user is coming from sign up, the url will have a token parameter
      if (token) {
        await authService.setToken(token);
        // remove the token from the URL
        url.searchParams.delete('auth');
      }

      // if the url contains an intended URL, redirect there
      const targetUrl = intent && !!intent.length ? `${window.location.origin}${intent}` : url.href;
      url.searchParams.delete('intent');

      history.replaceState(history.state, '', targetUrl);

      await dispatch(auth_checkForTokenAndLogin()).then(() => setAppStarting(false));
    })();
  }, [dispatch]);

  // Needed due to analitics.js not always setting the user_id as soon as the user logs in on web.
  useEffect(() => {
    const isAnalyticsIdSet = localStorage.getItem('ajs_user_id');

    if (user.hash_id && (!isAnalyticsIdSet || isAnalyticsIdSet === 'null')) {
      localStorage.setItem('ajs_user_id', user.hash_id);
    }
  }, [user]);

  if (isAppStarting || authLoadingStatuses?.isInitializing || authLoadingStatuses?.isLoading) {
    return <Loader isRouterLoader />;
  }

  const routes = createBrowserRouter([
    { path: '*', element: <NoMatch /> },
    {
      path: WebRoutes.root,
      element: <GuestTemplate user={user} />,
      children: [
        { path: WebRoutes.login, element: <SignInScreen /> },
        { path: WebRoutes.passwordRecovery, element: <PasswordRecoveryScreen /> },
      ],
    },
    ...BrowseRouter({ user }),
    ...InboxRouter({ user }),
    ...ProfileRouter({ user }),
    ...PropertyRouter({ user }),
    ...ServicesRouter({ user }),
  ]);

  return <RouterProvider router={routes} />;
}
