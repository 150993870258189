import { useMemo } from 'react';
import { Image, TouchableOpacity } from 'react-native';
import PoolSkimming from '@lawnstarter/customer-modules/assets/modal-images/pool-skimming.png';
import { POOL_ISSUE_OPTIONS } from '@lawnstarter/customer-modules/constants';
import { useOrderPoolFlowController } from '@lawnstarter/customer-modules/controllers';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { OrderPoolCleaningLayout } from '@src/components';

import { styles } from './styles';

import type { ImageSourcePropType } from 'react-native';

export function PoolVisibleIssuesScreen() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);
  const modal = useModal(ModalTemplate.SPINNER);

  const { selectedVisibleIssues, onIssuesSelected, onSubmitIssuesPressed, goToPreviousStep } =
    useOrderPoolFlowController({
      spinnerModal: {
        instance: modal,
        image: <Image source={PoolSkimming as ImageSourcePropType} style={style.image} />,
      },
    });

  return (
    <OrderPoolCleaningLayout
      title={t('services.poolCleaning.orderService.visibleIssues.title')}
      description={t('services.poolCleaning.orderService.visibleIssues.description')}
      progress={3 / 5}
      onBackButtonPress={goToPreviousStep}
    >
      <SelectOption.Multi
        options={POOL_ISSUE_OPTIONS}
        onSelect={onIssuesSelected}
        selected={selectedVisibleIssues}
      />

      <TouchableOpacity
        style={style.skipButton}
        onPress={() => onSubmitIssuesPressed({ skipped: true })}
      >
        <Text>{t('services.poolCleaning.orderService.visibleIssues.skipForNow')}</Text>
      </TouchableOpacity>

      <Button
        mode="contained"
        trackID={'pool_issues_next'}
        style={style.nextButton}
        onPress={() => onSubmitIssuesPressed()}
      >
        {t('next')}
      </Button>
    </OrderPoolCleaningLayout>
  );
}
