import { StyleSheet } from 'react-native';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = ({
  theme,
  isFloating,
  hasDescription,
}: {
  theme: AppTheme;
  isFloating: boolean;
  hasDescription: boolean;
}) => {
  if (isFloating) {
    return StyleSheet.create({
      priceContainer: {
        position: 'fixed' as any,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        width: '100%',
        marginTop: -theme.spacing.s5,
        backgroundColor: theme.colors.white.primary,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: -theme.sizing.s1 },
        shadowOpacity: 0.1,
        shadowRadius: theme.radius.s1,
      },
      content: {
        flexDirection: 'row',
        justifyContent: hasDescription ? 'space-between' : 'center',
        marginTop: theme.spacing.s4,
        marginHorizontal: theme.spacing.s5,
      },
      title: {
        lineHeight: theme.fonts.bodyMedium.lineHeight,
        fontSize: theme.fonts.bodyMedium.fontSize,
        ...setFontWeight('600'),
      },
      description: {},
      button: { margin: theme.spacing.s5 },
      footer: {
        marginHorizontal: theme.spacing.s4,
        marginBottom: theme.spacing.s5,
        alignSelf: 'center',
      },
    });
  }

  return StyleSheet.create({
    priceContainer: {
      alignItems: 'center',
      lineHeight: theme.spacing.s3 - 1,
      borderWidth: 1,
      borderRadius: theme.radius.s2,
      borderColor: theme.colors.gray.secondary,
      paddingTop: theme.spacing.s7,
      paddingRight: theme.spacing.s5,
      paddingBottom: theme.spacing.s5,
      paddingLeft: theme.spacing.s5,
      backgroundColor: theme.colors.white.primary,
      width: '100%',
    },
    content: {},
    title: {
      textAlign: 'center',
      lineHeight: theme.sizing.s10,
      ...setFontWeight('600'),
    },
    description: {
      marginTop: theme.spacing.s5,
      textAlign: 'center',
      ...setFontWeight('500'),
    },
    button: {
      width: '100%',
      maxWidth: 500,
      marginTop: theme.spacing.s10,
    },
    footer: {
      marginTop: theme.spacing.s5,
      color: theme.colors.gray.tertiary,
    },
  });
};
