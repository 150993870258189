import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { useConversationScreenController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  properties_currentPropertySelector,
  useGetConversationQuery,
  useRejectDaySwitchMutation,
  useSendMessageMutation,
  useStartConversationMutation,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { ConversationIntent } from '@lawnstarter/ls-react-common/enums';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ActionUnavailable } from '@lawnstarter/ls-react-common/molecules';
import {
  ConversationInput,
  ConversationMessage,
  ModalTemplate,
} from '@lawnstarter/ls-react-common/organisms';

import { Loader } from '@src/components';
import { useAppDownloadModal, useRouteNavigation, useSelector } from '@src/hooks';

import { DetailsContainer, InputContainer, PageContainer, styles, TitleContainer } from './styles';

import type { Message, User } from '@lawnstarter/ls-react-common/types';

export function ConversationScreen() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const { conversationList } = useConversationScreenController();

  const [rejectDaySwitch] = useRejectDaySwitchMutation();
  const [sendMessage] = useSendMessageMutation();
  const [startConversation] = useStartConversationMutation();

  const { navigate } = useRouteNavigation();
  const { conversationId = conversationList[0]?.conversation_id ?? 0, contractorUserId = 0 } =
    useParams();

  const [sendingMessage, setSendingMessage] = useState(false);
  const [isStartingConversation, setStartingConversation] = useState(false);

  const currentProperty = useSelector(properties_currentPropertySelector);
  const currentSchedule = useSelector(
    ({ schedules }) => schedules.schedulesById[currentProperty?.active_mowing_schedule_id],
  );

  const serviceAlreadyPausedConfirmation = useModal(ModalTemplate.CONFIRMATION);

  const {
    isFetching,
    isError,
    isLoading,
    data: currentConversation,
    isUninitialized,
  } = useGetConversationQuery(
    { conversationId: Number(conversationId) },
    { skip: !Number(conversationId) },
  );

  const { showAppDownloadModal } = useAppDownloadModal();
  const fieldRequired = t('forms.fieldRequired', { field: 'Message' });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      z.object({
        message: z.string({ required_error: fieldRequired }).min(1, fieldRequired),
      }),
    ),
  });

  const noMessage = useMemo(
    () => isError || !currentConversation?.messages?.length,
    [isError, currentConversation?.messages?.length],
  );

  const externalLoader = useMemo(
    () => isUninitialized && isFetching,
    [isUninitialized, isFetching],
  );

  const scrollIntoLastMessage = useMemo(
    () => !externalLoader && currentConversation?.customer?.user && currentConversation?.messages,
    [externalLoader, currentConversation?.customer?.user, currentConversation?.messages],
  );

  const lastMessageRef = useRef();

  const scrollToLastMessage = useCallback(() => {
    typeof document?.querySelector(`*[data-testid="${lastMessageRef}"]`)?.scrollIntoView ===
      'function' && document?.querySelector(`*[data-testid="${lastMessageRef}"]`)?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (!contractorUserId) return;

    const startConvoAndNavigate = async () => {
      setStartingConversation(true);

      const response = await startConversation({
        contractorUserId: Number(contractorUserId),
      }).unwrap();

      setStartingConversation(false);
      navigate(WebRoutes.conversation, {
        params: { conversationId: response.id },
        options: { replace: true },
      });
    };

    const activeConversation = conversationList.find(
      (conversation) => conversation.contractor?.user?.user_id === Number(contractorUserId),
    );

    if (activeConversation) {
      navigate(WebRoutes.conversation, {
        params: { conversationId: Number(activeConversation.id) },
        options: { replace: true },
      });

      return;
    }

    startConvoAndNavigate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!scrollIntoLastMessage) {
      return;
    }

    scrollToLastMessage();
  }, [scrollIntoLastMessage, scrollToLastMessage]);

  if ((isUninitialized && isFetching) || !currentConversation || isStartingConversation) {
    return <Loader />;
  }

  if (currentConversation && !currentConversation?.customer?.user) {
    navigate(WebRoutes.browseRedirect);
    return;
  }

  function renderTitle() {
    return (
      <div className="flex flex-col justify-center h-[80px] bg-primary mb-6 pl-6">
        <Text variant="titleLarge" style={style.title}>
          {currentConversation?.contractor?.name}
        </Text>
      </div>
    );
  }

  const renderAlreadyPausedConfirmation = () => {
    serviceAlreadyPausedConfirmation.show({
      description: t('conversation.alreadyPausedModal.description'),
      title: t('conversation.alreadyPausedModal.title'),
      buttons: [{ label: t('close') }],
      icon: 'pause-circle-outline',
    });
  };

  function onIntentActionPress({ message_id, ...params }: any) {
    const message = currentConversation?.messages.find(
      (message: Message) => message.id === message_id,
    );
    const intent = message?.link?.intent;

    const {
      scheduleEvent: { id: scheduleEventId = null, schedule = null } = {},
      schedule_event_day_switch_id: scheduleEventDaySwitchId = null,
    } = params || {};

    switch (intent) {
      case ConversationIntent.ServiceDaySwitch:
        return rejectDaySwitch({
          conversationId: Number(conversationId),
          customerId: currentConversation?.customer.id!,
          propertyId: schedule.property_id,
          scheduleId: schedule.schedule_id,
          scheduleEventId,
          scheduleEventDaySwitchId,
          message_id,
        });

      case ConversationIntent.ServicePause:
        if (!currentSchedule) {
          return;
        }
        if (currentSchedule?.is_paused) {
          return renderAlreadyPausedConfirmation();
        }
        return navigate(WebRoutes.pauseService, {
          params: {
            propertyId: currentProperty.id,
            scheduleId: currentProperty.active_mowing_schedule_id,
          },
        });

      default:
        return showAppDownloadModal();
    }
  }

  function rendeMessages() {
    if (isLoading && !sendingMessage) {
      return <Loader />;
    }

    if (noMessage) {
      return (
        <ActionUnavailable
          title={t('noMessagesAvailable.title')}
          description={t('conversation.currentlyNoMessages')}
        />
      );
    }

    const messages = currentConversation?.messages.map((message: Message) => (
      <ConversationMessage
        key={message.id}
        message={{
          id: message.id,
          message_id: message.message_id,
          created_at: message.created_at,
          user: message.user,
          text: message.text,
          link: message.link,
          thumbnail_url: message?.thumbnail_url,
          photo_url: message?.photo_url,
        }}
        onIntentActionPress={(params) => onIntentActionPress({ ...params, message_id: message.id })}
        isUpdatingIntent={false}
        disabled={false}
        currentProperty={currentProperty}
        userId={currentConversation.customer.user_id}
        fieldTexts={{
          you: message.user.first_name,
          keepSameDay: 'Keep Same Day',
          originalDayConfirmation: 'Original Day Confirmation',
          pauseSubscription: 'Pause Subscription',
          appUpdateRequired: 'App Update Required',
        }}
      />
    ));

    return (
      <>
        {messages}
        <div data-testid={lastMessageRef}></div>
      </>
    );
  }

  const onSubmit = async ({ message }: any) => {
    setSendingMessage(true);
    try {
      await sendMessage({
        conversationId: Number(conversationId),
        message: { text: message },
        user: currentConversation.customer.user as User,
      });

      setValue('message', '');
      scrollToLastMessage();
    } finally {
      setSendingMessage(false);
    }
  };

  return (
    <PageContainer>
      <TitleContainer>{renderTitle()}</TitleContainer>
      <DetailsContainer>{rendeMessages()}</DetailsContainer>
      <InputContainer>
        <ConversationInput
          control={control}
          errors={errors}
          isSendingMessage={sendingMessage}
          onSubmit={handleSubmit(onSubmit)}
          buttonText={t('send')}
        />
      </InputContainer>
    </PageContainer>
  );
}
