import { useMemo } from 'react';
import { View } from 'react-native';
import { useParams } from 'react-router-dom';
import { ScheduleSourceTypes } from '@lawnstarter/customer-modules/enums';
import { getJobInformation, getPropertyDetails } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { ServiceStandard } from '@lawnstarter/ls-react-common/enums';
import { ProQuoteViewer } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader, Loader } from '@src/components';
import { useSelector } from '@src/hooks';
import { errorService } from '@src/services';

import { StyledQuoteDetails } from './style';

import type {
  RecommendedServiceInformation,
  RequiredServiceInformation,
} from '@lawnstarter/ls-react-common/types';

export function ServiceQuoteDetailsScreen() {
  const { propertyId, scheduleId, sourceId } = useParams();

  const schedule = useSelector((state) => state.schedules.schedulesById[scheduleId as string]);
  const property = useSelector((state) => state.properties.propertiesById[propertyId as string]);
  const isLoading = useSelector((state) => state.schedules.loadingStatus.isLoading as boolean);

  const propertyDetails = useMemo(() => getPropertyDetails({ property }), [property]);

  const quote = useMemo(() => {
    const intent = {
      ...(schedule?.source_type === ScheduleSourceTypes.RecommendedQuote && {
        searchFor: ServiceStandard.Recommended,
      }),
      ...(schedule?.source_type === ScheduleSourceTypes.RequiredQuote && {
        searchFor: ServiceStandard.Required,
      }),
      resourceId: Number(sourceId),
    };

    return getJobInformation({ schedule, propertyDetails, errorService, intent });
  }, [schedule, propertyDetails, sourceId]) as
    | RecommendedServiceInformation
    | RequiredServiceInformation;

  if (isLoading) {
    return <Loader testID="service-quote-details-loader" />;
  }

  return (
    <View>
      <DetailsHeader title={quote?.service?.formattedName} showBackButton />
      <StyledQuoteDetails>
        {!isLoading && quote && (
          <ProQuoteViewer
            title={t('services.notes.title')}
            overview={quote.overview?.join(', ')}
            description={quote.description}
            images={quote.details?.images}
          />
        )}
      </StyledQuoteDetails>
    </View>
  );
}
