import { t } from '@lawnstarter/customer-modules/services';

import { OrderPriceConfirmation } from '@src/components';
import { useYearLongPoolController } from '@src/controllers';

import { StyledAboutContainer } from './styles';

export function AboutPoolCleaning({ isLoading = false }: { isLoading?: boolean }) {
  const { onCustomizePress } = useYearLongPoolController();

  return (
    <StyledAboutContainer>
      <OrderPriceConfirmation
        origin="about_pool_cleaning"
        isUpdating={isLoading}
        onButtonPress={onCustomizePress}
        labels={{
          title: t('services.poolCleaning.yearLong.poolPerfection'),
          button: t('services.poolCleaning.yearLong.letsCustomizeYourPlan'),
        }}
      />
    </StyledAboutContainer>
  );
}
