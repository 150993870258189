import { StyleSheet } from 'react-native';
import { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    image: { height: 120, width: 99 },
    skipButton: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing.s5,
      marginVertical: theme.spacing.s2,
    },
    nextButton: {
      width: '80%',
      marginHorizontal: 'auto',
    },
  });
};
