import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { AppTheme, setFontWeight } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    faqInfo: {
      marginTop: theme.spacing.s3,
      marginLeft: theme.spacing.s1,
    },

    orderLawnTreatmentContainer: {
      borderWidth: 1,
      borderRadius: theme.radius.s2,
      backgroundColor: theme.colors.white.primary,
      borderColor: theme.colors.gray.secondary,
      marginTop: theme.spacing.s3,
      padding: theme.spacing.s6,
    },

    orderLawnTreatmentSubtitle: {
      marginTop: theme.spacing.s5,
      ...setFontWeight('700'),
    },

    orderLawnTreatmentDescription: {
      marginTop: theme.spacing.s3,
    },

    orderLawnTreatmentItems: {
      marginTop: theme.spacing.s3,
      flexDirection: 'row',
      alignItems: 'center',
    },

    sheetTitle: {
      marginLeft: theme.spacing.s1,
      fontSize: theme.fonts.headlineSmall.fontSize,
      ...setFontWeight('600'),
    },

    planDetailsTitle: {
      marginTop: theme.spacing.s6,
      marginBottom: theme.spacing.s2,
      ...setFontWeight('500'),
    },

    imagesContainer: {
      marginTop: theme.spacing.s6,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    smallImagesContainer: {
      flex: 1,
      marginRight: theme.spacing.s2,
      alignItems: 'center',
    },

    smallImage: {
      height: 160,
      width: '100%',
    },

    smallImageSubtitle: {
      position: 'absolute',
      top: '110%',
      transform: [{ translateX: 0 }, { translateY: -50 }],
      color: 'white',
      textAlign: 'center',
    },

    largeImagesContainer: {
      marginTop: theme.spacing.s3,
      alignItems: 'center',
    },

    largeImage: {
      height: 400,
      width: '100%',
    },

    largeImageSubtitle: {
      position: 'absolute',
      top: '98%',
      transform: [{ translateX: 0 }, { translateY: -50 }],
      color: 'white',
      textAlign: 'center',
    },

    footer: {
      padding: 10,
      marginTop: theme.spacing.s2,
      color: theme.colors.gray.tertiary,
      ...setFontWeight('500'),
    },

    goBackContainer: {
      width: theme.sizing.s6,
      height: theme.sizing.s6,
    },
  });
};

export const StyledOrderLawnTreatmentContainer = tw.section`
  flex flex-col w-full items-start
  md:flex-row-reverse md:gap-6
`;

export const OrderLawnTreatmentContainer = tw.div`
  w-full 
  md:w-[454px] 
  lg:w-[549px] 
  xl:w-[613px] 
  [@media(min-width:2000px)]:w-[900px]
`;

export const StyledOrderLawnTreatmentInfoContainer = tw.div`
  w-full mx-auto
  md:w-8/12
`;
