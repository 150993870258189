import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { AppTheme, setFontWeight } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    orderPoolCleaningContainer: {
      borderWidth: 1,
      borderRadius: theme.radius.s2,
      backgroundColor: theme.colors.white.primary,
      borderColor: theme.colors.gray.secondary,
      marginTop: theme.spacing.s3,
      padding: theme.spacing.s6,
    },

    orderPoolCleaningSubtitle: {
      marginTop: theme.spacing.s5,
      ...setFontWeight('700'),
    },

    orderPoolCleaningDescription: {
      marginTop: theme.spacing.s3,
    },

    orderPoolCleaningItems: {
      marginTop: theme.spacing.s3,
      flexDirection: 'row',
      alignItems: 'center',
    },

    imagesContainer: {
      marginTop: theme.spacing.s6,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    smallImagesContainer: {
      flex: 1,
      alignItems: 'center',
    },

    smallImagesContainerPadding: {
      flex: 1,
      alignItems: 'center',
      marginLeft: theme.spacing.s3,
    },

    smallImage: {
      height: 160,
      width: '100%',
    },

    largeImagesContainer: {
      marginTop: theme.spacing.s3,
      alignItems: 'center',
    },

    largeImage: {
      height: 400,
      width: '100%',
    },

    footer: {
      padding: 10,
      marginTop: theme.spacing.s2,
      color: theme.colors.gray.tertiary,
      ...setFontWeight('500'),
    },

    greatPoolTransformations: {
      marginTop: theme.spacing.s6,
      marginBottom: -theme.spacing.s4,
      ...setFontWeight('700'),
    },
  });
};

export const StyledOrderPoolCleaningContainer = tw.section`
  flex flex-col w-full items-start
  md:flex-row-reverse md:gap-6
`;

export const OrderPoolCleaningContainer = tw.div`
  w-full mb-[130px]
  md:w-[454px]
  lg:w-[549px]
  xl:w-[613px]
  [@media(min-width:2000px)]:w-[900px]
`;

export const StyledOrderPoolCleaningInfoContainer = tw.div`
  w-full mx-auto
  md:w-8/12
`;
