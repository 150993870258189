import {
  API_BASE_URL,
  EXPO_PUBLIC_APP_NAME,
  EXPO_PUBLIC_APP_VERSION,
  EXPO_PUBLIC_ENV,
  SEGMENT_KEY,
  SENTRY_PROJECT_SLUG,
} from '@env';
import { AnalyticsBrowser } from '@segment/analytics-next';
import * as Sentry from '@sentry/react';
import { TRACKING_EVENT_EXPERIMENT_VIEWED } from '@lawnstarter/customer-modules/constants';
import { AppEnv } from '@lawnstarter/ls-react-common/enums';
import {
  createApiService,
  createApiTrackingService,
  createAuthService,
  createEngagementService,
  createErrorService,
  createHeaderService,
  createTrackingService,
} from '@lawnstarter/ls-react-common/services';

// Cross-platform services
export const authService = createAuthService();

export const engagementService = createEngagementService();

export const errorService = createErrorService({
  Sentry,
  env: EXPO_PUBLIC_ENV,
  appName: EXPO_PUBLIC_APP_NAME,
  appVersion: EXPO_PUBLIC_APP_VERSION,
  sentryProjectSlug: SENTRY_PROJECT_SLUG,
});

export const trackingService = createTrackingService({
  analytics: AnalyticsBrowser.load({ writeKey: SEGMENT_KEY }),
  engagementService,
  appName: EXPO_PUBLIC_APP_NAME,
  appVersion: EXPO_PUBLIC_APP_VERSION,
  env: EXPO_PUBLIC_ENV as AppEnv,
  appEvents: {
    [TRACKING_EVENT_EXPERIMENT_VIEWED]: TRACKING_EVENT_EXPERIMENT_VIEWED,
  },
});

export const headersService = createHeaderService({
  appName: EXPO_PUBLIC_APP_NAME,
  appVersion: EXPO_PUBLIC_APP_VERSION,
  authService,
  engagementService,
});

export const apiService = createApiService({
  apiBaseUrl: API_BASE_URL,
  errorService,
  headersService,

  // We'll rely on the navigator to tell if it's online or not
  isDeviceOnline: () => Promise.resolve(navigator.onLine),
});

export const apiTrackingService = createApiTrackingService({
  apiService,
});
