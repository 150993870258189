import { DEVICE_WIDTH_BREAKPOINTS } from '@src/constants';
import { DeviceType } from '@src/enums';
import { useWindowSize } from '@src/hooks/useWindowSize';

export const useDeviceType = () => {
  const { width } = useWindowSize();

  if (width <= DEVICE_WIDTH_BREAKPOINTS.tablet.max) {
    return DeviceType.Portable;
  }

  if (
    width >= DEVICE_WIDTH_BREAKPOINTS.laptops.min &&
    width <= DEVICE_WIDTH_BREAKPOINTS.desktop.max
  ) {
    return DeviceType.Desktop;
  }

  return DeviceType.LargeDesktop;
};
