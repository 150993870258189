import { StyleSheet } from 'react-native';
import { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    icon: {
      width: theme.sizing.s9,
      height: theme.sizing.s9,
      borderColor: theme.colors.gray.secondary,
      backgroundColor: theme.colors.gray.secondary,
      borderRadius: 9999,
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
};
