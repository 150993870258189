import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const style = (theme: AppTheme) =>
  StyleSheet.create({
    noticeTitle: {
      ...setFontWeight('700'),
      color: theme.colors.primary,
    },
    noticeButton: {
      ...setFontWeight('600'),
      color: theme.colors.blue.primary,
    },
  });

export const StyledWrapper = tw.div`
  wakawaka 
  h-full flex flex-col
  md:block md:px-5 md:pb-5 md:h-max
`;

export const StyledButtonWrapper = tw.div`
  p-5
`;
