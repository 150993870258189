import { Navigate } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';

import { useRouteCreator } from '@src/hooks';
import {
  AuthorizedProfileTemplate,
  BillingHistoryScreen,
  ChangePasswordScreen,
  HelpScreen,
  ProfileDetailsScreen,
  PropertyDetailsScreen,
  ShareScreen,
  TermsAndPoliciesScreen,
  UpdatePaymentScreen,
} from '@src/pages';
import { NotificationPreferencesScreen } from '@src/pages/Authorized/Profile/NotificationPreferencesScreen/NotificationPreferencesScreen';

import type { User } from '@lawnstarter/ls-react-common/types';
import type { RouteObject } from 'react-router-dom';

export function ProfileRouter({ user }: { user: User }): RouteObject[] {
  const { createRoute } = useRouteCreator();

  return [
    {
      path: WebRoutes.profile,
      element: <AuthorizedProfileTemplate user={user} />,
      children: [
        createRoute({
          index: true,
          component: <ProfileDetailsScreen />,
        }),
        createRoute({
          key: 'personal-info',
          path: WebRoutes.personalInformation,
          component: <ProfileDetailsScreen />,
        }),
        createRoute({
          path: WebRoutes.profilePropertyDetails,
          component: <PropertyDetailsScreen />,
        }),
        createRoute({
          path: WebRoutes.updatePayment,
          component: <UpdatePaymentScreen />,
        }),
        createRoute({
          path: WebRoutes.billingHistory,
          component: <BillingHistoryScreen />,
        }),
        {
          path: WebRoutes.billing,
          element: <Navigate to={WebRoutes.billingHistory} replace />,
        },
        createRoute({
          path: WebRoutes.changePassword,
          component: <ChangePasswordScreen />,
        }),
        createRoute({
          path: WebRoutes.notificationPreferences,
          component: <NotificationPreferencesScreen />,
        }),
      ],
    },
    {
      path: WebRoutes.help,
      element: <AuthorizedProfileTemplate user={user} />,
      children: [
        createRoute({
          index: true,
          component: <HelpScreen />,
        }),
      ],
    },
    {
      path: WebRoutes.helpAndSupport,
      element: <Navigate to={WebRoutes.help} replace />,
    },
    {
      path: WebRoutes.termsAndPolicies,
      element: <AuthorizedProfileTemplate user={user} />,
      children: [
        createRoute({
          index: true,
          component: <TermsAndPoliciesScreen />,
        }),
      ],
    },
    {
      path: WebRoutes.share,
      element: <AuthorizedProfileTemplate user={user} />,
      children: [
        createRoute({
          index: true,
          component: <ShareScreen />,
        }),
      ],
    },
    {
      path: WebRoutes.shareOnNextDoor,
      element: <Navigate to={WebRoutes.share} replace />,
    },
  ];
}
