import { useMemo } from 'react';
import { Pressable } from 'react-native';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon } from '@lawnstarter/ls-react-common/atoms';

import { useRouteNavigation } from '@src/hooks';

import { styles } from './styles';

import type { ViewStyle } from 'react-native';

interface Props {
  onPress?: () => Promise<void> | void;
  iconStyle?: ViewStyle;
}

export function BackButton({ onPress, iconStyle }: Props = {}) {
  const theme = useAppTheme();
  const { navigate } = useRouteNavigation();
  const style = useMemo(() => styles(theme), [theme]);

  return (
    <Pressable
      testID="back-button"
      accessibilityLabel={t('goBack')}
      onPress={onPress || (() => navigate(WebRoutes.back))}
    >
      <Icon name="arrow-left" size={theme.sizing.s6} style={[style.icon, iconStyle]} />
    </Pressable>
  );
}
