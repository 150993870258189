import { StyleSheet } from 'react-native';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) =>
  StyleSheet.create({
    sheetContainer: {
      zIndex: 20,
      backgroundColor: theme.colors.white.primary,
      padding: theme.spacing.s5,
      paddingTop: 0,
      paddingBottom: 0,
      overflowY: 'scroll',
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing.s4,
    },
    headerTitle: {
      ...setFontWeight('600'),
      fontSize: theme.fonts.titleLarge.fontSize,
    },
    closeButtonLabel: {
      marginLeft: 'auto',
    },
  });
