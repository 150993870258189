import { useFlags } from 'flagsmith/react';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { getOrderServiceInfo } from '@lawnstarter/customer-modules/helpers';
import { getTrackingEventSource } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  auth_signedUserSelector,
  orderServices_orderPricedLtrsService,
} from '@lawnstarter/customer-modules/stores/modules';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { useDispatch, useRouteNavigation, useSelector, useWindowSize } from '@src/hooks';

export const useOrderLawnTreatmentController = () => {
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();
  const { propertyId, servicePromoId } = useParams();
  const { width } = useWindowSize();
  const location = useLocation();
  const modal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];

  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;

  const { email: customerEmail } = useSelector(auth_signedUserSelector);
  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);
  const lawnTreatmentInfo = useSelector(
    ({ properties }) =>
      properties.propertiesById[propertyId as string]?.priced?.services?.fertilization,
  );

  const serviceInfo = useMemo(
    () =>
      getOrderServiceInfo({
        service: ServiceType.LawnTreatment,
        priceInfo: { price: Number(lawnTreatmentInfo?.options[0].price ?? 0) },
      }),
    [lawnTreatmentInfo],
  );

  const onContinue = () =>
    navigate(WebRoutes.confirmLawnTreatment, {
      params: { propertyId: propertyId as string },
      options: { state: { source } },
    });

  const onSubmit = async () => {
    const { success, error } = await dispatch(
      orderServices_orderPricedLtrsService({
        property_id: propertyId,
        service_promo_id: servicePromoId,
        service_id: lawnTreatmentInfo?.service.id,
        price_id: lawnTreatmentInfo?.options[0].price_id,
        source,
      }),
    );

    if (success) {
      const message = t('confirmationServiceOrder.message', {
        customerEmail,
        serviceName: lawnTreatmentInfo?.service.name,
      });

      modal.show({
        icon: 'checkbox-marked-circle-plus-outline',
        action: t('confirmationServiceOrder.title'),
        messages: [message],
        buttonText: t('goToMyServices'),
        onDismiss: () => navigate(WebRoutes.services, { options: { replace: true } }),
      });
    } else {
      modal.show({
        icon: 'block-helper',
        action: t('somethingWentWrong'),
        messages: [error?.error?.message ?? t('apiGenericError')],
        buttonText: t('tryAgain'),
      });
    }
  };

  return {
    width,
    source,
    navigate,
    onSubmit,
    onContinue,
    isUpdating,
    serviceInfo,
    lawnTreatmentInfo,
  };
};
