import { EXPO_PUBLIC_APP_VERSION } from '@env';
import { useFlags } from 'flagsmith/react';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { getProfileSectionsList } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import {
  auth_signOut,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { ActionList } from '@lawnstarter/ls-react-common/organisms';

import { useAppDownloadModal, useDispatch, useRouteNavigation, useSelector } from '@src/hooks';

import { StyledLogOutWrapper } from './styles';

import type { ActionListSectionItemProps } from '@lawnstarter/customer-modules/helpers';

export function ProfileScreen() {
  const theme = useAppTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { navigate, parseRoute } = useRouteNavigation();

  const { showAppDownloadModal } = useAppDownloadModal();

  const propertyId = useSelector(properties_currentPropertyIdSelector);

  const isItemActive = useMemo(
    () => (link: WebRoutes) => {
      if (pathname === WebRoutes.profile) {
        // Parent route, mark first item as active
        return link === WebRoutes.personalInformation;
      }

      return parseRoute(link, { params: { propertyId } }) === pathname;
    },
    [parseRoute, pathname, propertyId],
  );

  const brazeNotificationPreferences = useFlags([
    FeatureFlags.BRAZE_NOTIFICATIONS_PREFERENCE_CENTER,
  ])[FeatureFlags.BRAZE_NOTIFICATIONS_PREFERENCE_CENTER];

  const renderProfileOptions = useCallback(() => {
    const list = getProfileSectionsList({
      origin: 'profile_screen',
      withChangePassword: true,
      withNotificationPreferences: brazeNotificationPreferences.enabled,
    });

    return list.map(({ title, items }) => {
      const actionItems = items.map((item) => {
        const action = [WebRoutes.share, WebRoutes.pastServices].includes(item.linkPath)
          ? showAppDownloadModal
          : () => navigate(item.linkPath, { params: { propertyId } });

        return {
          ...item,
          active: isItemActive(item.linkPath),
          action: () => action(),
        } as ActionListSectionItemProps;
      });

      return <ActionList key={title} title={title} items={actionItems} />;
    });
  }, [brazeNotificationPreferences, isItemActive, navigate, propertyId, showAppDownloadModal]);

  return (
    <div className="md:pb-4 md:px-4">
      {renderProfileOptions()}

      <StyledLogOutWrapper>
        <Button
          mode="contained"
          trackID="profile_screen-sign_out"
          contentStyle={{ width: '90vw' }}
          onPress={async () => await dispatch(auth_signOut())}
        >
          {t('profile.logOut.button')}
        </Button>

        <Text style={{ marginTop: theme.spacing.s2 }}>
          {t('versionNumber', { version: EXPO_PUBLIC_APP_VERSION })}
        </Text>
      </StyledLogOutWrapper>
    </div>
  );
}
