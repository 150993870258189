import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';

export function useStyles() {
  const theme = useAppTheme();

  return StyleSheet.create({
    modalContainer: {
      paddingHorizontal: theme.spacing.s4,
    },
    modalTitle: {
      marginTop: -theme.spacing.s6,
      ...setFontWeight('600'),
    },
    modalContent: {
      color: theme.colors.gray.tertiary,
      marginTop: theme.spacing.s5,
      rowGap: theme.spacing.s4,
    },
    bottomSheetTitle: {
      ...setFontWeight('600'),
    },
    bottomSheetText: {
      marginBottom: theme.spacing.s3,
    },
  });
}

export const BackButtonWrapper = tw.div`
  mx-auto mt-6
  lg:w-4/5
`;
