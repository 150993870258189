import { useMemo } from 'react';
import { Image, SafeAreaView, View } from 'react-native';
import Slider from 'react-slick';
import LawnTreatmentMonth1Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_1.png';
import LawnTreatmentMonth5Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_5.png';
import LawnTreatmentMonth11Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_11.png';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';

import { OrderPriceConfirmation, ResponsiveRenderer } from '@src/components';
import { PlanDetailsTable } from '@src/components/molecules/Table/PlanDetailsTable.tsx';
import { StyledCarouselContainer } from '@src/components/organisms/RecommendationCarousel/styles.ts';
import { useOrderLawnTreatmentController } from '@src/controllers';

import { StyledOrderLawnTreatmentInfoContainer, styles } from './MobileStyles';

import type { ImageSourcePropType } from 'react-native';

export function MobileOrderLawnTreatmentTemplate() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const { onContinue, isUpdating, serviceInfo } = useOrderLawnTreatmentController();

  const getCarouselItems = () => {
    return [
      {
        title: '',
        imageUrl: LawnTreatmentMonth1Photo,
        text: t('ltrs.orderLawnTreatment.imageSubtitles.monthOne'),
        actionText: '',
        action: { routeName: '' },
      },
      {
        title: '',
        imageUrl: LawnTreatmentMonth5Photo,
        text: t('ltrs.orderLawnTreatment.imageSubtitles.monthFive'),
        actionText: '',
        action: { routeName: '' },
      },
      {
        title: '',
        imageUrl: LawnTreatmentMonth11Photo,
        text: t('ltrs.orderLawnTreatment.imageSubtitles.monthEleven'),
        actionText: '',
        action: { routeName: '' },
      },
    ];
  };

  const renderItems = () => {
    return getCarouselItems().map((item, index) => (
      <View key={index} style={style.imagesContainer}>
        <View style={style.smallImagesContainer}>
          <Image source={item.imageUrl as ImageSourcePropType} style={style.smallImage} />
          <Text variant="labelMedium" style={style.smallImageSubtitle}>
            {item.text}
          </Text>
        </View>
      </View>
    ));
  };

  const renderOrderLawnTreatmentContainer = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
    };

    return (
      <StyledCarouselContainer>
        <Slider {...settings}>{renderItems()}</Slider>
      </StyledCarouselContainer>
    );
  };

  return (
    <SafeAreaView style={style.safeArea}>
      <ResponsiveRenderer>
        <ResponsiveRenderer.Portable>
          <ScrollView contentContainerStyleOverride={{ flexGrow: 1 }}>
            <StyledOrderLawnTreatmentInfoContainer>
              <View style={style.orderLawnTreatmentContainer}>
                <Text variant="labelLarge" style={style.orderLawnTreatmentSubtitle}>
                  {t('ltrs.orderLawnTreatment.subtitle')}
                </Text>

                <Text variant="labelLarge" style={style.orderLawnTreatmentDescription}>
                  {t('ltrs.orderLawnTreatment.description')}
                </Text>

                <View style={style.orderLawnTreatmentItems}>
                  <Icon name="card-search-outline" size={theme.sizing.s6} />
                  <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                    {t('ltrs.orderLawnTreatment.items.certifiedPro')}
                  </Text>
                </View>

                <View style={style.orderLawnTreatmentItems}>
                  <Icon name="star-circle-outline" size={theme.sizing.s6} />
                  <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                    {t('ltrs.orderLawnTreatment.items.guaranteedFreeTouchUps')}
                  </Text>
                </View>

                <View style={style.orderLawnTreatmentItems}>
                  <Icon name="bone" size={theme.sizing.s6} />
                  <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
                    {t('ltrs.orderLawnTreatment.items.safeForPetsAndFamilies')}
                  </Text>
                </View>

                <Text variant="bodyLarge" style={style.planDetailsTitle}>
                  {t('ltrs.orderLawnTreatment.planDetails')}
                </Text>

                <PlanDetailsTable />

                {renderOrderLawnTreatmentContainer()}

                <Text variant="bodySmall" style={style.footer}>
                  {t('ltrs.orderLawnTreatment.footer')}
                </Text>
              </View>
            </StyledOrderLawnTreatmentInfoContainer>
          </ScrollView>
        </ResponsiveRenderer.Portable>

        <ResponsiveRenderer.Desktop>
          <View>
            <Text variant="headlineLarge">{t('ltrs.orderLawnTreatment.title')}</Text>
          </View>
        </ResponsiveRenderer.Desktop>
      </ResponsiveRenderer>

      <OrderPriceConfirmation
        origin="mobile_order_lawn_treatment_screen"
        isUpdating={isUpdating}
        onButtonPress={onContinue}
        isFloating={true}
        labels={{
          title: serviceInfo?.service.prices[0],
          description: serviceInfo?.service.frequency,
          footer: serviceInfo?.service.billedUpon,
          button: t('continue'),
        }}
      />
    </SafeAreaView>
  );
}
