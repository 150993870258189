import { EXPO_PUBLIC_HELP_CENTER_CUSTOMER_POLICY_FAQ_WITHIN_48H } from '@env';
import { View } from 'react-native';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { RichText } from '@lawnstarter/ls-react-common/atoms';
import { currency } from '@lawnstarter/ls-react-common/filters';
import { TitleHighlighted } from '@lawnstarter/ls-react-common/molecules';
import { Confirmation, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import imageURL from '@src/assets/man-lawn-mowing.svg';

import { style } from './style';

import type { Props } from './types';

export function CancellationDeflectionFeeSameDay(props: Props) {
  const theme = useAppTheme();
  const styles = style(theme);

  const feeAmount = currency({ amount: props.feeAmount * 0.01, trimTrailingZeros: true });

  const onLinkPress = () => {
    window.open(EXPO_PUBLIC_HELP_CENTER_CUSTOMER_POLICY_FAQ_WITHIN_48H, '_blank');
  };

  const icon = (
    <img
      alt="Person mowing a small patch of grass"
      data-testid="image"
      height={176}
      loading="lazy"
      src={imageURL}
      width={161}
    />
  );

  const title = (
    <TitleHighlighted
      contents={[
        {
          text: t('cancelService.deflection.feeSameDay.title.wait'),
        },
        {
          text: t('cancelService.deflection.feeSameDay.title.yourServiceIs'),
        },
        {
          text: t('cancelService.deflection.feeSameDay.title.scheduledFor'),
        },
        {
          text: t('cancelService.deflection.feeSameDay.title.today'),
          highlight: true,
        },
      ]}
      style={{ justifyContent: 'center' }}
    />
  );

  const description = (
    <View style={{ alignContent: 'center' }}>
      <RichText textStyle={styles.description}>
        {t('cancelService.deflection.feeSameDay.description.line1')}
      </RichText>
      <RichText textStyle={styles.description}>
        {t('cancelService.deflection.feeSameDay.description.line2', { feeAmount })}
      </RichText>
      <RichText
        linkActions={{ onLinkPress }}
        textStyle={styles.link}
        containerStyle={styles.linkContainer}
      >
        {t('cancelService.deflection.feeSameDay.description.line3')}
      </RichText>
    </View>
  );

  const buttons = [
    {
      label: t('nevermindDontCancel'),
      onPress: props.onPrimaryPress,
    },
    {
      label: t('payAndCancel', { feeAmount }),
      mode: 'text' as const,
      onPress: props.onSecondaryPress,
    },
  ];

  return (
    <ModalTemplate>
      <Confirmation
        buttons={buttons}
        description={description}
        icon={icon}
        origin="cancellation-deflection-fee-same-day"
        stacked="always"
        title={title}
      />
    </ModalTemplate>
  );
}
