import _ from 'lodash';
import { Children, isValidElement } from 'react';

import { DeviceType } from '@src/enums';
import { useDeviceType } from '@src/hooks';

import type { PropsWithChildren, ReactNode } from 'react';

type CheckFnProps = { deviceType: DeviceType; childType: string };

/**
 * Provides a responsive renderer which can be used to render Portable, Desktop and LargeDesktop contents dynamically.
 *
 * @example
 * // Responsive Renderer usage
 * <ResponsiveRenderer>
 *   // Only displayed on Portable screens (width < 768px)
 *   <ResponsiveRenderer.Portable>Portable content</ResponsiveRenderer.Portable>
 *
 *   // Only displayed on Desktop screens (width >= 769px and width <= 1200px)
 *   // Note: If no LargeDesktop content is provided, Desktop content will be displayed on LargeDesktop screens as well.
 *   <ResponsiveRenderer.Desktop>Desktop content</ResponsiveRenderer.Desktop>
 *
 *   // Only displayed on LargeDesktop screens (width >= 1201px)
 *   <ResponsiveRenderer.LargeDesktop>Large desktop content</ResponsiveRenderer.LargeDesktop>
 *  </ResponsiveRenderer>
 */
const ResponsiveRenderer = ({
  children,
  hidden = false,
}: PropsWithChildren<{ hidden?: boolean }>) => {
  const deviceType = useDeviceType();

  if (hidden) {
    return null;
  }

  return Children.toArray(children)
    .sort(sortByWeight)
    .filter((e) => isValidElement(e))
    .find((child) => {
      const childType = getChildType(child);

      if (isPortable({ deviceType, childType })) {
        return child;
      }

      if (isLargeDesktop({ deviceType, childType })) {
        return child;
      }

      if (isDesktop({ deviceType, childType })) {
        return child;
      }
    });
};

const PortableResponsive = ({ children }: PropsWithChildren) => children;
const DesktopResponsive = ({ children }: PropsWithChildren) => children;
const LargeDesktopResponsive = ({ children }: PropsWithChildren) => children;

// Additional properties to allow the ResponsiveRenderer to be used after build and minification
PortableResponsive.prototype = { type: DeviceType.Portable, weight: 10 };
DesktopResponsive.prototype = { type: DeviceType.Desktop, weight: 30 };
LargeDesktopResponsive.prototype = { type: DeviceType.LargeDesktop, weight: 20 };

ResponsiveRenderer.Portable = PortableResponsive;
ResponsiveRenderer.Desktop = DesktopResponsive;
ResponsiveRenderer.LargeDesktop = LargeDesktopResponsive;

export { ResponsiveRenderer };

/**
 * Gets the type of the child component
 * @param child ReactNode
 */
const getChildType = (child: ReactNode): string =>
  _.get(child, 'type.prototype.type', '') || _.get(child, 'type.name', '');

/**
 * Sorts the children based on the weight property
 * @param a ReactNode
 * @param b ReactNode
 */
const sortByWeight = (a: ReactNode, b: ReactNode) => {
  const aWeight = _.get(a, 'type.prototype.weight', 0);
  const bWeight = _.get(b, 'type.prototype.weight', 0);

  return aWeight - bWeight;
};

/**
 * Check functions to determine if the device type is Portable
 * @param deviceType
 * @param type
 */
const isPortable = ({ deviceType, childType }: CheckFnProps) => {
  return (
    deviceType === DeviceType.Portable &&
    ['PortableResponsive', DeviceType.Portable].includes(childType)
  );
};

/**
 * Check functions to determine if the device type is Desktop
 * @param deviceType
 * @param type
 */
const isDesktop = ({ deviceType, childType }: CheckFnProps) => {
  return (
    [DeviceType.Desktop, DeviceType.LargeDesktop].includes(deviceType) &&
    ['DesktopResponsive', DeviceType.Desktop].includes(childType)
  );
};

/**
 * Check functions to determine if the device type is LargeDesktop
 * @param deviceType
 * @param type
 */
const isLargeDesktop = ({ deviceType, childType }: CheckFnProps) => {
  return (
    deviceType === DeviceType.LargeDesktop &&
    ['LargeDesktopResponsive', DeviceType.LargeDesktop].includes(childType)
  );
};
