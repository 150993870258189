import { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Spinner, Text } from '@lawnstarter/ls-react-common/atoms';

import { styles } from './styles';

import type { PriceConfirmationProps } from '../types.ts';

export const OrderPriceConfirmation = ({
  origin,
  labels,
  onButtonPress,
  isUpdating = false,
  isFloating = false,
}: PriceConfirmationProps) => {
  const theme = useAppTheme();
  const style = useMemo(
    () => styles({ theme, isFloating, hasDescription: !!labels?.description }),
    [theme, isFloating, labels?.description],
  );

  const renderTitle = useCallback(() => {
    if (!labels.title) {
      return <Spinner testID="price-spinner" />;
    }

    return (
      <Text allowFontScaling={false} variant="displaySmall" style={style.title}>
        {labels.title}
      </Text>
    );
  }, [labels, style]);

  return (
    <View style={style.priceContainer}>
      <View />

      <View style={style.content}>
        {renderTitle()}

        {labels.description && (
          <Text allowFontScaling={false} style={style.description}>
            {labels.description}
          </Text>
        )}
      </View>

      <Button
        mode="contained"
        trackID={`${origin}-submit_order`}
        style={style.button}
        loading={isUpdating}
        disabled={isUpdating}
        onPress={onButtonPress}
      >
        {labels.button}
      </Button>

      {labels.footer && <Text style={style.footer}>{labels.footer}</Text>}
    </View>
  );
};
