import { StyleSheet } from 'react-native';
import tw from 'tailwind-styled-components';
import { setFontWeight } from '@lawnstarter/ls-react-common';

import type { AppTheme } from '@lawnstarter/ls-react-common';

export const styles = (theme: AppTheme) => {
  return StyleSheet.create({
    safeArea: {
      flex: 1,
      // Necessary to accommodate our new footer.
      marginBottom: theme.spacing.s8,
      zIndex: theme.spacing.s5,
    },
    contentContainer: {
      flex: 1,
      padding: 0,
    },
    orderPoolCleaningContainer: {
      backgroundColor: theme.colors.white.primary,
      padding: theme.spacing.s4,
      width: '100%',
    },
    orderPoolCleaningSubtitle: {
      ...setFontWeight('700'),
    },
    orderPoolCleaningDescription: {
      marginTop: theme.spacing.s3,
    },
    orderPoolCleaningItems: {
      marginTop: theme.spacing.s3,
      flexDirection: 'row',
    },
    imagesContainer: {
      marginTop: theme.spacing.s5,
      flexDirection: 'column',
    },
    smallImagesContainer: {
      flex: 1,
      alignItems: 'center',
      marginVertical: theme.spacing.s3,
    },
    smallImage: {
      height: 240,
      width: '100%',
    },
    smallImageSubtitle: {
      position: 'absolute',
      top: '110%',
      transform: [{ translateX: 0 }, { translateY: -50 }],
      color: 'white',
      textAlign: 'center',
    },
    footer: {
      padding: theme.spacing.s6,
      marginTop: theme.spacing.s2,
      color: theme.colors.gray.tertiary,
      textAlign: 'center',
      ...setFontWeight('500'),
    },
    greatPoolTransformations: {
      marginTop: theme.spacing.s6,
      ...setFontWeight('700'),
    },
  });
};

export const StyledOrderPoolCleaningInfoContainer = tw.section`
  flex flex-col items-center w-full max-w-screen-lg mx-auto mb-16
`;
