import BottomSheet from '@devvie/bottom-sheet';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import tw from 'tailwind-styled-components';
import { eventEmitter } from '@lawnstarter/customer-modules/helpers';
import { useToggle } from '@lawnstarter/customer-modules/hooks';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon } from '@lawnstarter/ls-react-common/atoms';

import { MobileHeaderEvents } from '@src/enums';
import { useScrollBlock } from '@src/hooks';

import { NewPropertyForm } from '../NewPropertyForm';
import { PropertyList } from '../PropertyList';
import { styles } from './styles';

import type { BottomSheetMethods } from '@devvie/bottom-sheet';

export function PropertyBottomSheet() {
  const [sheetVisibility, updateSheetVisibility] = useState(false);
  const sheetRef = useRef<BottomSheetMethods>(null);

  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const [blockScroll, allowScroll] = useScrollBlock();
  const [isAddPropertyOpen, toggleAddPropertyOpen] = useToggle();

  // Listen to open requests
  useEffect(() => {
    const propertySelectPress = () => {
      sheetRef.current?.open();
      blockScroll();
    };

    const source = eventEmitter.on(
      MobileHeaderEvents.onPropertySelectPressEvent,
      propertySelectPress,
    );

    return () => {
      source.removeListener(MobileHeaderEvents.onPropertySelectPressEvent, propertySelectPress);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeBottomSheet = useCallback(() => {
    sheetRef.current?.close();
    allowScroll();
    if (isAddPropertyOpen) {
      toggleAddPropertyOpen();
    }
  }, [allowScroll, toggleAddPropertyOpen, isAddPropertyOpen]);

  return (
    <StyledPropertyBottomSheetWrapper $sheetVisibility={sheetVisibility}>
      <BottomSheet
        height={'86%'}
        ref={sheetRef}
        style={{ zIndex: 20, backgroundColor: theme.colors.white.primary }}
        onAnimate={(animated: number) => {
          // Avoid weird visual behaviors
          if (animated === 0 && sheetVisibility) {
            updateSheetVisibility(false);
          } else if (animated > 0 && !sheetVisibility) {
            updateSheetVisibility(true);
          }
        }}
        onClose={() => {
          allowScroll();
          if (isAddPropertyOpen) {
            toggleAddPropertyOpen();
          }
        }}
        android_closeOnBackPress={false}
      >
        <View style={style.closeButtonContainer}>
          <Button
            labelStyle={style.closeButtonLabel}
            textColor={theme.colors.gray.tertiary}
            trackID="close-property"
            onPress={closeBottomSheet}
            useLowercase
          >
            <Icon name="close" />
          </Button>
        </View>

        <View style={{ maxHeight: '95%', padding: theme.spacing.s5 }}>
          {isAddPropertyOpen ? (
            <NewPropertyForm onGoBack={toggleAddPropertyOpen} onClose={closeBottomSheet} />
          ) : (
            <PropertyList onClose={closeBottomSheet} />
          )}
        </View>

        {/* Logic to switch between list and new property form */}
      </BottomSheet>
    </StyledPropertyBottomSheetWrapper>
  );
}

const StyledPropertyBottomSheetWrapper = tw.div<{ $sheetVisibility: boolean }>`
  ${(params) => (params.$sheetVisibility ? 'z-10' : '-z-10')}
  fixed top-0 right-0 left-0 h-full
`;
