import PoolCleaningPhoto2 from '@lawnstarter/customer-modules/assets/services/pool_cleaning2.png';
import PoolCleaningPhoto1 from '@lawnstarter/customer-modules/assets/services/pool_image10.png';
import PoolCleaningPhoto3 from '@lawnstarter/customer-modules/assets/services/pool_image12.png';
import PoolCleaningPhoto4 from '@lawnstarter/customer-modules/assets/services/pool_image15.png';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { properties_currentPropertyIdSelector } from '@lawnstarter/customer-modules/stores/modules';

import { useRouteNavigation, useSelector, useWindowSize } from '@src/hooks';

export const useYearLongPoolController = () => {
  const { width } = useWindowSize();
  const { navigate } = useRouteNavigation();

  const propertyId = useSelector(properties_currentPropertyIdSelector);

  const onCustomizePress = () => {
    navigate(WebRoutes.orderPoolCleaningFlow, { params: { propertyId } });
  };

  const getCarouselItems = () =>
    [PoolCleaningPhoto1, PoolCleaningPhoto2, PoolCleaningPhoto3, PoolCleaningPhoto4].flatMap(
      (poolPhoto) => ({
        title: '',
        imageUrl: poolPhoto,
        text: '',
        actionText: '',
        action: { routeName: '' },
      }),
    );

  return {
    width,
    getCarouselItems,
    onCustomizePress,
  };
};
