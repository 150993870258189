import { useMemo } from 'react';
import { Image, View } from 'react-native';
import LawnTreatmentMonth1Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_1.png';
import LawnTreatmentMonth5Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_5.png';
import LawnTreatmentMonth11Photo from '@lawnstarter/customer-modules/assets/services/lawn_treatment_month_11.png';
import { FaqTopics } from '@lawnstarter/customer-modules/enums';
import { getFaqByTopic } from '@lawnstarter/customer-modules/helpers';
import { useOrderServiceViewedTracking } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, RichText, Text } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking } from '@lawnstarter/ls-react-common/hooks';
import { Question } from '@lawnstarter/ls-react-common/molecules';

import {
  AboutLawnTreatment,
  BackButton,
  HelperBottomSheet,
  ResponsiveRenderer,
} from '@src/components';
import { PlanDetailsTable } from '@src/components/molecules/Table/PlanDetailsTable.tsx';
import { useOrderLawnTreatmentController } from '@src/controllers';
import { errorService } from '@src/services';

import { MobileOrderLawnTreatmentTemplate } from './MobileOrderLawnTreatmentTemplate';
import {
  OrderLawnTreatmentContainer,
  StyledOrderLawnTreatmentContainer,
  StyledOrderLawnTreatmentInfoContainer,
  styles,
} from './styles';

import type { ImageSourcePropType } from 'react-native';

export function OrderLawnTreatmentScreen() {
  const theme = useAppTheme();
  const style = useMemo(() => styles(theme), [theme]);

  const apiTracking = useApiTracking()!;
  const { source, onContinue, isUpdating, lawnTreatmentInfo } = useOrderLawnTreatmentController();

  useOrderServiceViewedTracking(apiTracking, ServiceType.LawnTreatment, errorService, source);

  const renderOrderLawnTreatmentContainer = () => {
    return (
      <StyledOrderLawnTreatmentInfoContainer>
        <BackButton />

        <OrderLawnTreatmentContainer style={style.orderLawnTreatmentContainer}>
          <Text variant="headlineLarge">{t('ltrs.orderLawnTreatment.title')}</Text>

          <br />
          <View style={{ marginBottom: 16 }} />

          <Text variant="labelLarge" style={style.orderLawnTreatmentSubtitle}>
            {t('ltrs.orderLawnTreatment.subtitle')}
          </Text>

          <View style={{ marginBottom: 12 }} />

          <Text variant="labelLarge" style={style.orderLawnTreatmentDescription}>
            {t('ltrs.orderLawnTreatment.description')}
          </Text>

          <View style={style.orderLawnTreatmentItems}>
            <Icon name="card-search-outline" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('ltrs.orderLawnTreatment.items.certifiedPro')}
            </Text>
          </View>

          <View style={style.orderLawnTreatmentItems}>
            <Icon name="star-circle-outline" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('ltrs.orderLawnTreatment.items.guaranteedFreeTouchUps')}
            </Text>
          </View>

          <View style={style.orderLawnTreatmentItems}>
            <Icon name="bone" size={theme.sizing.s6} />
            <Text variant="labelLarge" style={{ marginLeft: theme.sizing.s2 }}>
              {t('ltrs.orderLawnTreatment.items.safeForPetsAndFamilies')}
            </Text>
          </View>

          <View>
            <Text variant="bodyLarge" style={style.planDetailsTitle} allowFontScaling={false}>
              {t('ltrs.orderLawnTreatment.planDetails')}
            </Text>
          </View>
          <PlanDetailsTable />

          <View style={style.imagesContainer}>
            <View style={style.smallImagesContainer}>
              <Image
                source={LawnTreatmentMonth1Photo as ImageSourcePropType}
                style={style.smallImage}
              />
              <Text variant="labelMedium" style={style.smallImageSubtitle}>
                {t('ltrs.orderLawnTreatment.imageSubtitles.monthOne')}
              </Text>
            </View>
            <View style={style.smallImagesContainer}>
              <Image
                source={LawnTreatmentMonth5Photo as ImageSourcePropType}
                style={style.smallImage}
              />
              <Text variant="labelMedium" style={style.smallImageSubtitle}>
                {t('ltrs.orderLawnTreatment.imageSubtitles.monthFive')}
              </Text>
            </View>
          </View>

          <View style={style.largeImagesContainer}>
            <Image
              source={LawnTreatmentMonth11Photo as ImageSourcePropType}
              style={style.largeImage}
            />
            <Text variant="labelMedium" style={style.largeImageSubtitle}>
              {t('ltrs.orderLawnTreatment.imageSubtitles.monthEleven')}
            </Text>
            <Text variant="bodySmall" style={style.footer}>
              {t('ltrs.orderLawnTreatment.footer')}
            </Text>
          </View>
        </OrderLawnTreatmentContainer>
      </StyledOrderLawnTreatmentInfoContainer>
    );
  };

  const renderLawnTreatmentComponents = () => {
    return (
      <AboutLawnTreatment
        isLoading={isUpdating}
        price={lawnTreatmentInfo?.options[0].price ?? ''}
        onPress={onContinue}
      />
    );
  };

  const renderFAQContent = () =>
    getFaqByTopic({ topic: FaqTopics.lawnTreatment }).map(({ questions }) =>
      questions.map(({ question, answers }) => (
        <Question key={question} question={question} trackID="about_lawn_treatment-ltrs_faq_items">
          <>
            {answers.map(({ text, actions }) => (
              <RichText
                key={text}
                linkActions={actions}
                textStyle={style.faqInfo}
                linkStyle={{ color: theme.colors.blue.primary }}
              >
                {text}
              </RichText>
            ))}
          </>
        </Question>
      )),
    );

  const renderSheetTitle = () => (
    <Text variant="bodyMedium" style={style.sheetTitle}>
      {t('ltrs.faq.bottomSheetTitle')}
    </Text>
  );

  return (
    <ResponsiveRenderer>
      <ResponsiveRenderer.Portable>
        <MobileOrderLawnTreatmentTemplate />
        <HelperBottomSheet content={renderFAQContent()} title={renderSheetTitle()} />
      </ResponsiveRenderer.Portable>

      <ResponsiveRenderer.Desktop>
        <StyledOrderLawnTreatmentContainer>
          {renderLawnTreatmentComponents()}
          {renderOrderLawnTreatmentContainer()}
        </StyledOrderLawnTreatmentContainer>
      </ResponsiveRenderer.Desktop>
    </ResponsiveRenderer>
  );
}
